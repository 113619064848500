<template>
  <div>
    <v-app :style="colorVapp">
      <onebox_toolbar
        :parentfolder="parentfolder"
        @inputdata="filteredItems"
        @loadfile="loadfolder()"
        @callparentfolder="getSourceSystem()"
        @callstorage="loadstorage()"
      ></onebox_toolbar>
      <v-content>
        <v-overlay :value="processloader" absolute :color="color.BG" dark>
        <v-progress-circular
          indeterminate
          size="64"
          :color="color.theme"
        ></v-progress-circular>
        <br />
        <br />
        <span :style="colorProgress">&nbsp; &nbsp; loading</span>
      </v-overlay>
      <v-overlay :value="loaddataprogress_print" z-index="2" color="black" dark>
        <v-progress-circular
          indeterminate
          size="64"
          color="white"
        ></v-progress-circular>
        <span :style="colorProgress">&nbsp; loading</span>
      </v-overlay>
        <!-- หน้าจอมือถือ -->
        <v-list
          nav
          :color="color.BG"
          class="px-4"
          v-if="resolutionScreen < 500"
        >
          <v-card
            rounded="xl"
            class="elevation-0 d-flex align-center"
            height="40"
          >
            <v-list-item class="text-left">
              <!-- <v-btn
                @mouseover="show_back = true"
                @mouseleave="show_back = false"
                fab
                small
                :color="color.theme"
                @click="backtopreventfolder(), (show_back = false)"
                class="ma-2"
              >
                <v-icon color="white">keyboard_backspace</v-icon>
                <v-badge
                  v-if="$t('default') === 'th'"
                  style="z-index:10000;bottom:-45px;left:-35px;"
                  :value="show_back"
                  :content="$t('balloon.back')"
                  transition="slide-y-transition"
                ></v-badge>
                <v-badge
                  v-else
                  style="z-index:10000;bottom:-45px;left:-27px;"
                  :value="show_back"
                  :content="$t('balloon.back')"
                  transition="slide-y-transition"
                ></v-badge>
              </v-btn> -->
              <v-list-item-avatar class="text-center">
                <v-icon dark size="24" :color="color.theme">face</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  <span :style="headerPage"
                    >{{ $t("toolbar.myinbox.Personalname") }}
                  </span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-list>

        <v-list nav :color="color.BG" v-else>
          <v-list-item class="text-left">
            <!-- <v-btn
              @mouseover="show_back = true"
              @mouseleave="show_back = false"
              fab
              small
              :color="color.theme"
              @click="backtopreventfolder(), (show_back = false)"
              class="ma-2"
            >
              <v-icon color="white">keyboard_backspace</v-icon>
              <v-badge
                v-if="$t('default') === 'th'"
                style="z-index:10000;bottom:-45px;left:-35px;"
                :value="show_back"
                :content="$t('balloon.back')"
                transition="slide-y-transition"
              ></v-badge>
              <v-badge
                v-else
                style="z-index:10000;bottom:-45px;left:-27px;"
                :value="show_back"
                :content="$t('balloon.back')"
                transition="slide-y-transition"
              ></v-badge>
            </v-btn> -->
            <v-list-item-avatar class="text-center">
              <v-avatar :color="color.theme" size="35">
                <v-icon dark size="20">face</v-icon>
              </v-avatar>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                <span :style="headerPage">{{
                  $t("toolbar.myinbox.Personalname")
                }}</span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-card
          class="elevation-0"
          :color="color.BG"
          id="thiscontainer_inboxsourcesystem"
        >
          <v-card-text class="pa-0">
            <v-container fluid>
              <v-layout row wrap justify-center>
                <v-flex lg12 xs12 class="mr-4 ml-4">
                  <!-- ค้นหาหน้าจอธรรมดา -->
                  <div v-if="resolutionScreen >= 400">
                    <v-expansion-panels v-model="panel" multiple outlined>
                      <v-expansion-panel class="pa-1">
                        <v-expansion-panel-header>
                          <div>
                            <span :style="headerPage">{{
                              $t("dcmPage.conditionFile")
                            }}</span>
                            <p class="ml-2 mt-2" style="color:#E70034">
                              ( {{ $t("conditions") }}
                              {{ $t("textconditions") }} )
                            </p>
                          </div>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content class="pa-1">
                          <v-form>
                            <v-layout row wrap fill-height justify-center>
                              <v-flex xs12 sm12 md5 lg5 class="pa-1">
                                <v-container fluid>
                                  <v-layout row wrap justify-center>
                                    <!-- ช่องกรอก เอกสารลงวันที่ -->
                                    <v-flex xs12 sm12 md6 lg6>
                                      <v-dialog
                                        ref="dialogFromdatedocument"
                                        v-model="modalFromdatedocument"
                                        :return-value.sync="fromdatedocument"
                                        persistent
                                        full-width
                                        width="290px"
                                        :color="color.theme"
                                      >
                                        <template v-slot:activator="{ on }">
                                          <v-text-field
                                            :color="color.theme"
                                            :label="
                                              $t(
                                                'myinboxPage.conditionOptional.fromdatedocument'
                                              )
                                            "
                                            v-model="fromdatedocument"
                                            @click:prepend="
                                              modalFromdatedocument = true
                                            "
                                            prepend-icon="event"
                                            v-on="on"
                                            readonly
                                            outlined
                                            dense
                                            clearable
                                            :persistent-hint="fromdate_hint"
                                            @click:clear="todatedocument = ''"
                                            class="my-hint-style pa-1 pt-0 pb-0"
                                          ></v-text-field>
                                          <!-- ปรับใหม่เป็นช่องวันที่แบบกรอก กดไอคอนเลือกวัน -->
                                          <!-- <v-text-field
                                        :color="color.theme"
                                        :label="$t('myinboxPage.conditionOptional.fromdatedocument')"
                                        v-model="fromdatedocument"
                                        @click:prepend="modalFromdatedocument = true, fromdatedocument = datenow_eng,on"
                                        prepend-icon="event"
                                        :placeholder="$t('myinboxPage.yyyy_mm_dd')"
                                        outlined
                                        dense
                                        clearable
                                        :persistent-hint="fromdate_hint"
                                        @click:clear="todatedocument = ''"
                                        class="my-hint-style pa-1 pt-0 pb-0"
                                      ></v-text-field> -->
                                        </template>
                                        <!-- :min="setdate_(todatedocument)" -->
                                        <v-date-picker
                                          v-model="fromdatedocument"
                                          scrollable
                                          :locale="$t('default')"
                                          @input="changeFromdatedocument()"
                                          :color="color.theme"
                                          :max="todatedocument"
                                        >
                                          <v-spacer></v-spacer>
                                          <v-btn
                                            dark
                                            color="error"
                                            @click.stop="
                                              modalFromdatedocument = false
                                            "
                                            >{{
                                              $t(
                                                "myinboxPage.conditionOptional.closefromdatedocument"
                                              )
                                            }}</v-btn
                                          >
                                        </v-date-picker>
                                      </v-dialog>
                                    </v-flex>

                                    <!-- ช่องกรอก เอกสารลงวันที่ -->
                                    <v-flex xs12 sm12 md6 lg6>
                                      <v-dialog
                                        ref="dialogTodatedocument"
                                        v-model="modalTodatedocument"
                                        :return-value.sync="todatedocument"
                                        persistent
                                        full-width
                                        width="290px"
                                        :color="color.theme"
                                      >
                                        <template v-slot:activator="{ on }">
                                          <v-text-field
                                            readonly
                                            :color="color.theme"
                                            :label="
                                              $t(
                                                'myinboxPage.conditionOptional.todatedocument'
                                              )
                                            "
                                            v-model="todatedocument"
                                            prepend-icon="event"
                                            @click:prepend="
                                              modalTodatedocument = true
                                            "
                                            v-on="on"
                                            outlined
                                            dense
                                            clearable
                                            :persistent-hint="todate_hint"
                                            class="my-hint-style pa-1 pt-0 pb-0"
                                            :disabled="
                                              fromdatedocument === '' ||
                                                fromdatedocument === null
                                            "
                                          ></v-text-field>
                                          <!-- ปรับใหม่เป็นช่องวันที่แบบกรอก กดไอคอนเลือกวัน -->
                                          <!-- <v-text-field
                                        :color="color.theme"
                                        :label="$t('myinboxPage.conditionOptional.todatedocument')"
                                        v-model="todatedocument"
                                        prepend-icon="event"
                                        @click:prepend="modalTodatedocument = true, todatedocument = datenow_eng,on"
                                        :placeholder="$t('myinboxPage.yyyy_mm_dd')"
                                        outlined
                                        dense
                                        clearable
                                        :persistent-hint="todate_hint"
                                        class="my-hint-style pa-1 pt-0 pb-0"
                                      ></v-text-field> -->
                                        </template>
                                        <v-date-picker
                                          v-model="todatedocument"
                                          scrollable
                                          :locale="$t('default')"
                                          @input="
                                            $refs.dialogTodatedocument.save(
                                              todatedocument
                                            )
                                          "
                                          :min="fromdatedocument"
                                          :max="setdate(fromdatedocument)"
                                          :color="color.theme"
                                        >
                                          <v-spacer></v-spacer>
                                          <v-btn
                                            dark
                                            color="error"
                                            @click.stop="
                                              modalTodatedocument = false
                                            "
                                            >{{
                                              $t(
                                                "myinboxPage.conditionOptional.closetodatedocument"
                                              )
                                            }}</v-btn
                                          >
                                        </v-date-picker>
                                      </v-dialog>
                                    </v-flex>
                                  </v-layout>

                                  <v-layout row wrap justify-center>
                                    <!-- ช่องวันที่ได้รับไฟล์ -->
                                    <v-flex xs12 sm12 md6 lg6>
                                      <v-dialog
                                        ref="dialogFromdateupload"
                                        v-model="modalFromdateupload"
                                        :return-value.sync="fromdateupload"
                                        persistent
                                        full-width
                                        width="290px"
                                        :color="color.theme"
                                      >
                                        <template v-slot:activator="{ on }">
                                          <v-text-field
                                            clearable
                                            :color="color.theme"
                                            :label="
                                              $t(
                                                'myinboxPage.conditionOptional.fromdateupload'
                                              )
                                            "
                                            v-model="fromdateupload"
                                            @click:prepend="
                                              modalFromdateupload = true
                                            "
                                            prepend-icon="event"
                                            v-on="on"
                                            readonly
                                            outlined
                                            dense
                                            @click:clear="todateupload = ''"
                                            class="my-hint-style pa-1 pt-0 pb-0"
                                          ></v-text-field>
                                          <!-- ปรับใหม่เป็นช่องวันที่แบบกรอก กดไอคอนเลือกวัน -->
                                          <!-- <v-text-field
                                        clearable
                                        :color="color.theme"
                                        :label="$t('myinboxPage.conditionOptional.fromdateupload')"
                                        v-model="fromdateupload"
                                        prepend-icon="event" 
                                        @click:prepend="modalFromdateupload = true, fromdateupload = datenow_eng,on"
                                        :placeholder="$t('myinboxPage.yyyy_mm_dd')"
                                        outlined
                                        dense
                                        @click:clear="todateupload = ''"
                                        class="my-hint-style pa-1 pt-0 pb-0"
                                        @input="format_input(fromdateupload)"

                                      ></v-text-field> -->
                                        </template>
                                        <!-- :min="setdate_(todateupload)" -->
                                        <v-date-picker
                                          v-model="fromdateupload"
                                          scrollable
                                          :locale="$t('default')"
                                          @input="changeFromdateupload()"
                                          :color="color.theme"
                                          :max="todateupload"
                                        >
                                          <v-spacer></v-spacer>
                                          <v-btn
                                            dark
                                            color="error"
                                            @click.stop="
                                              modalFromdateupload = false
                                            "
                                            >{{
                                              $t(
                                                "myinboxPage.conditionOptional.closefromdateupload"
                                              )
                                            }}</v-btn
                                          >
                                        </v-date-picker>
                                      </v-dialog>
                                    </v-flex>
                                    <!-- ช่องวันที่ได้รับไฟล์ -->
                                    <v-flex xs12 sm12 md6 lg6>
                                      <v-dialog
                                        ref="dialogTodateupload"
                                        v-model="modalTodateupload"
                                        :return-value.sync="todateupload"
                                        persistent
                                        full-width
                                        width="290px"
                                        :color="color.theme"
                                      >
                                        <template v-slot:activator="{ on }">
                                          <v-text-field
                                            clearable
                                            readonly
                                            :color="color.theme"
                                            :label="
                                              $t(
                                                'myinboxPage.conditionOptional.todateupload'
                                              )
                                            "
                                            v-model="todateupload"
                                            prepend-icon="event"
                                            @click:prepend="
                                              modalTodateupload = true
                                            "
                                            v-on="on"
                                            outlined
                                            dense
                                            class="my-hint-style pa-1 pt-0 pb-0"
                                            :disabled="
                                              fromdateupload === '' ||
                                                fromdateupload === null
                                            "
                                          ></v-text-field>
                                          <!-- ปรับใหม่เป็นช่องวันที่แบบกรอก กดไอคอนเลือกวัน -->
                                          <!-- <v-text-field
                                        clearable
                                        :color="color.theme"
                                        :label="$t('myinboxPage.conditionOptional.todateupload')"
                                        v-model="todateupload"
                                        prepend-icon="event"
                                        @click:prepend="modalTodateupload = true, todateupload = datenow_eng,on"
                                        :placeholder="$t('myinboxPage.yyyy_mm_dd')"
                                        outlined
                                        dense
                                        class="my-hint-style pa-1 pt-0 pb-0"
                                      ></v-text-field> -->
                                        </template>
                                        <v-date-picker
                                          v-model="todateupload"
                                          scrollable
                                          :locale="$t('default')"
                                          @input="
                                            $refs.dialogTodateupload.save(
                                              todateupload
                                            )
                                          "
                                          :min="fromdateupload"
                                          :max="setdate(fromdateupload)"
                                          :color="color.theme"
                                        >
                                          <v-spacer></v-spacer>
                                          <v-btn
                                            dark
                                            color="error"
                                            @click.stop="
                                              modalTodateupload = false
                                            "
                                            >{{
                                              $t(
                                                "myinboxPage.conditionOptional.closetodateupload"
                                              )
                                            }}</v-btn
                                          >
                                        </v-date-picker>
                                      </v-dialog>
                                    </v-flex>
                                  </v-layout>
                                  <v-layout row wrap justify-center>
                                    <v-flex xs12 sm12 md6 lg6>
                                      <v-text-field
                                        clearable
                                        outlined
                                        dense
                                        @click:clear="clearDocumentId"
                                        class="pa-1 pt-0 pb-0"
                                        v-model="documentid"
                                        :label="
                                          $t(
                                            'myinboxPage.conditionOptional.filedocumentid'
                                          )
                                        "
                                        prepend-icon="format_list_numbered"
                                        :color="color.theme"
                                      ></v-text-field>
                                    </v-flex>
                                    <v-flex xs12 sm12 md6 lg6>
                                      <v-text-field
                                        clearable
                                        outlined
                                        :disabled="!!!documentid"
                                        dense
                                        class="pa-1 pt-0 pb-0"
                                        v-model="documentidto"
                                        :label="
                                          $t(
                                            'myinboxPage.conditionOptional.todateupload'
                                          )
                                        "
                                        prepend-icon="format_list_numbered"
                                        :color="color.theme"
                                      ></v-text-field>
                                    </v-flex>
                                  </v-layout>
                                </v-container>
                              </v-flex>

                              <!-- <v-flex xs1 sm1 md1 lg1> -->
                              <div class="pt-0">
                                <div
                                  class="pt-6"
                                  @mouseover="show_info_doc = true"
                                  @mouseleave="show_info_doc = false"
                                  fab
                                  x-small
                                >
                                  <v-icon color="primary"
                                    >mdi-information</v-icon
                                  >
                                  <v-badge
                                    v-if="$t('default') === 'th'"
                                    style="z-index:10000;bottom:-30px;left:-35px;"
                                    :value="show_info_doc"
                                    :content="$t('balloon.info_date_doc')"
                                    transition="slide-y-transition"
                                  ></v-badge>
                                  <v-badge
                                    v-else
                                    style="z-index:10000;bottom:-30px;left:-27px;"
                                    :value="show_info_doc"
                                    :content="$t('balloon.info_date_doc')"
                                    transition="slide-y-transition"
                                  ></v-badge>
                                </div>
                                <div
                                  class="pt-10"
                                  @mouseover="show_info = true"
                                  @mouseleave="show_info = false"
                                  fab
                                  x-small
                                >
                                  <v-icon color="primary"
                                    >mdi-information</v-icon
                                  >
                                  <v-badge
                                    v-if="$t('default') === 'th'"
                                    style="z-index:10000;bottom:-30px;left:-35px;"
                                    :value="show_info"
                                    :content="$t('balloon.info_date')"
                                    transition="slide-y-transition"
                                  ></v-badge>
                                  <v-badge
                                    v-else
                                    style="z-index:10000;bottom:-30px;left:-27px;"
                                    :value="show_info"
                                    :content="$t('balloon.info_date')"
                                    transition="slide-y-transition"
                                  ></v-badge>
                                </div>
                              </div>
                              <!-- </v-flex> -->

                              <v-flex xs12 sm12 md1 lg1 class="text-center">
                                <v-divider vertical></v-divider>
                              </v-flex>

                              <v-flex xs12 sm12 md5 lg5 class="text-center">
                                <v-container fluid>
                                  <!-- ช่องกรอก BuyerTaxID -->
                                  <v-layout row wrap justify-center>
                                    <!-- ช่องกรอก ผู้รับ -->
                                    <v-flex xs12 sm12 md6 lg6>
                                      <v-text-field
                                        outlined
                                        dense
                                        clearable
                                        class="pa-1 pt-0 pb-0"
                                        v-model="receivername"
                                        :label="
                                          $t(
                                            'myinboxPage.conditionOptional.receivername2'
                                          )
                                        "
                                        prepend-icon="account_circle"
                                        :color="color.theme"
                                      ></v-text-field>
                                    </v-flex>
                                    <v-flex xs12 sm12 md6 lg6>
                                      <v-text-field
                                        clearable
                                        outlined
                                        dense
                                        class="pa-1 pt-0 pb-0"
                                        v-model="buyertaxid"
                                        :label="
                                          $t(
                                            'myinboxPage.conditionOptional.buyertaxid2'
                                          )
                                        "
                                        prepend-icon="mdi-domain"
                                        :color="color.theme"
                                      ></v-text-field>
                                    </v-flex>
                                    <!-- <v-flex xs12 sm12 md6 lg6>
                                    <v-text-field
                                      clearable
                                      outlined
                                      dense
                                      class="pa-1 pt-0 pb-0"
                                      v-model="buyerbranchid"
                                      :label="
                                        $t(
                                          'myinboxPage.conditionOptional.buyerbranchid'
                                        )
                                      "
                                      prepend-icon="mdi-format-list-bulleted-square"
                                      :color="color.theme"
                                    ></v-text-field>
                                  </v-flex> -->
                                  </v-layout>
                                  <!-- ช่องกรอก SellerTaxID -->
                                  <v-layout row wrap justify-center>
                                    <v-flex xs12 sm12 md6 lg6>
                                      <v-text-field
                                        outlined
                                        dense
                                        clearable
                                        class="pa-1 pt-0 pb-0"
                                        v-model="sendername"
                                        :label="
                                          $t(
                                            'myinboxPage.conditionOptional.sendername2'
                                          )
                                        "
                                        prepend-icon="account_box"
                                        :color="color.theme"
                                      ></v-text-field>
                                    </v-flex>
                                    <v-flex xs12 sm12 md6 lg6>
                                      <v-text-field
                                        clearable
                                        outlined
                                        dense
                                        class="pa-1 pt-0 pb-0"
                                        v-model="sellertaxid"
                                        :label="
                                          $t(
                                            'myinboxPage.conditionOptional.sellertaxid2'
                                          )
                                        "
                                        prepend-icon="mdi-domain"
                                        :color="color.theme"
                                      ></v-text-field>
                                    </v-flex>
                                    <!-- <v-flex xs12 sm12 md6 lg6>
                                    <v-text-field
                                      clearable
                                      outlined
                                      dense
                                      class="pa-1 pt-0 pb-0"
                                      v-model="sellerbranchid"
                                      :label="
                                        $t(
                                          'myinboxPage.conditionOptional.sellerbranchid'
                                        )
                                      "
                                      prepend-icon="mdi-format-list-bulleted-square"
                                      :color="color.theme"
                                    ></v-text-field>
                                  </v-flex> -->
                                  </v-layout>

                                  <v-layout row wrap justify-center>
                                    <!-- ช่องเลือก ประเภเอกสาร -->
                                    <v-flex xs12 sm12 md6 lg6>
                                      <v-select
                                        clearable
                                        outlined
                                        dense
                                        class="pa-1 pt-0 pb-0"
                                        v-model="documenttype"
                                        :items="listDoctype"
                                        item-value="document_type_code"
                                        :item-text="
                                          $t('default') === 'th'
                                            ? 'document_type_th'
                                            : 'document_type_eng'
                                        "
                                        :label="
                                          $t(
                                            'myinboxPage.conditionOptional.doctype'
                                          )
                                        "
                                        prepend-icon="line_style"
                                        :color="color.theme"
                                        :item-color="color.theme"
                                        multiple
                                      >
                                        <template v-slot:prepend-item>
                                          <v-list-item
                                            ripple
                                            @click="fn_selectall"
                                          >
                                            <v-list-item-action>
                                              <v-icon
                                                :color="
                                                  documenttype.length > 0
                                                    ? 'indigo darken-4'
                                                    : ''
                                                "
                                              >
                                                {{ icon }}
                                              </v-icon>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                              <v-list-item-title>
                                                Select All
                                              </v-list-item-title>
                                            </v-list-item-content>
                                          </v-list-item>
                                          <v-divider class="mt-2"></v-divider>
                                        </template>
                                        <template
                                          v-slot:selection="{ item, index }"
                                        >
                                          <span v-if="index < maxDisplay"
                                            >{{
                                              $t("default") === "th"
                                                ? item.document_type_th
                                                : item.document_type_eng
                                            }}
                                            ,
                                          </span>
                                          <span
                                            v-if="index === maxDisplay"
                                            class="grey--text caption"
                                            >(+{{
                                              documenttype.length - maxDisplay
                                            }}
                                            ประเภท)</span
                                          >
                                        </template>
                                      </v-select>
                                    </v-flex>
                                    <!-- สถานะขอคืนภาษี -->
                                    <v-flex xs12 sm12 md6 lg6>
                                      <v-select
                                        clearable
                                        outlined
                                        dense
                                        class="pa-1 pt-0 pb-0"
                                        v-model="statusetax"
                                        :items="liststatusetax"
                                        item-value="statusetax_value"
                                        item-text="statusetax_type"
                                        :label="
                                          $t(
                                            'myinboxPage.conditionOptional.taxabb'
                                          )
                                        "
                                        prepend-icon="reorder"
                                        :color="color.theme"
                                        :item-color="color.theme"
                                      >
                                        <!-- <template v-slot:prepend-item>
                                          <v-list-item
                                            ripple
                                            @click="fn_selectall_statusetax"
                                          >
                                            <v-list-item-action>
                                              <v-icon
                                                :color="
                                                  statusetax.length > 0
                                                    ? 'indigo darken-4'
                                                    : ''
                                                "
                                              >
                                                {{ icon_ }}
                                              </v-icon>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                              <v-list-item-title>
                                                Select All
                                              </v-list-item-title>
                                            </v-list-item-content>
                                          </v-list-item>
                                          <v-divider class="mt-2"></v-divider>
                                        </template> -->
                                      </v-select>
                                    </v-flex>
                                    <!-- chanel -->
                                    <!-- <v-flex xs12 sm12 md6 lg6>
                                  <v-select
                                    clearable
                                    outlined
                                    dense
                                    class="pa-1 pt-0 pb-0"
                                    v-model="chanel"
                                    :items="listDoctype"
                                    item-value="document_type"
                                    item-text="document_type"
                                    :label="$t('myinboxPage.conditionOptional.chanel')"
                                    prepend-icon="reorder"
                                    :color="color.theme"
                                    :item-color="color.theme"
                                  ></v-select>
                                </v-flex> -->
                                  </v-layout>
                                </v-container>
                              </v-flex>
                            </v-layout>
                          </v-form>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </div>

                  <!--ค้นหาหน้าจอมือถือ -->
                  <div v-else>
                    <v-expansion-panels v-model="panel" multiple outlined>
                      <v-expansion-panel class="pa-1">
                        <v-expansion-panel-header>
                          <div>
                            <span :style="headerPage">{{
                              $t("dcmPage.conditionFile")
                            }}</span>
                            <p class="ml-2" style="color:#E70034">
                              ( {{ $t("conditions") }}
                              {{ $t("textconditions") }} )
                            </p>
                          </div>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content class="pa-1">
                          <v-form>
                            <v-layout row wrap fill-height justify-center>
                              <v-flex xs12 sm12 md5 lg5 class="pa-1">
                                <v-container fluid>
                                  <v-layout row wrap justify-center>
                                    <!-- ช่องกรอก เอกสารลงวันที่ -->
                                    <v-flex xs12 sm12 md6 lg6>
                                      <v-dialog
                                        ref="dialogFromdatedocument"
                                        v-model="modalFromdatedocument"
                                        :return-value.sync="fromdatedocument"
                                        persistent
                                        full-width
                                        width="290px"
                                        :color="color.theme"
                                      >
                                        <template v-slot:activator="{ on }">
                                          <v-text-field
                                            :color="color.theme"
                                            :label="
                                              $t(
                                                'myinboxPage.conditionOptional.fromdatedocument'
                                              )
                                            "
                                            v-model="fromdatedocument"
                                            @click:prepend="
                                              modalFromdatedocument = true
                                            "
                                            prepend-icon="event"
                                            v-on="on"
                                            readonly
                                            outlined
                                            dense
                                            clearable
                                            :persistent-hint="fromdate_hint"
                                            @click:clear="todatedocument = ''"
                                            class="my-hint-style pa-1 pt-0 pb-0"
                                          ></v-text-field>
                                          <!-- ปรับใหม่เป็นช่องวันที่แบบกรอก กดไอคอนเลือกวัน -->
                                          <!-- <v-text-field
                                        :color="color.theme"
                                        :label="$t('myinboxPage.conditionOptional.fromdatedocument')"
                                        v-model="fromdatedocument"
                                        @click:prepend="modalFromdatedocument = true, fromdatedocument = datenow_eng,on"
                                        prepend-icon="event"
                                        :placeholder="$t('myinboxPage.yyyy_mm_dd')"
                                        outlined
                                        dense
                                        clearable
                                        :persistent-hint="fromdate_hint"
                                        @click:clear="todatedocument = ''"
                                        class="my-hint-style pa-1 pt-0 pb-0"
                                      ></v-text-field> -->
                                        </template>
                                        <!-- :min="setdate_(todatedocument)" -->
                                        <v-date-picker
                                          v-model="fromdatedocument"
                                          scrollable
                                          :locale="$t('default')"
                                          @input="changeFromdatedocument()"
                                          :color="color.theme"
                                          :max="todatedocument"
                                        >
                                          <v-spacer></v-spacer>
                                          <v-btn
                                            dark
                                            color="error"
                                            @click.stop="
                                              modalFromdatedocument = false
                                            "
                                            >{{
                                              $t(
                                                "myinboxPage.conditionOptional.closefromdatedocument"
                                              )
                                            }}</v-btn
                                          >
                                        </v-date-picker>
                                      </v-dialog>
                                    </v-flex>

                                    <!-- ช่องกรอก เอกสารลงวันที่ -->
                                    <v-flex xs12 sm12 md6 lg6>
                                      <v-dialog
                                        ref="dialogTodatedocument"
                                        v-model="modalTodatedocument"
                                        :return-value.sync="todatedocument"
                                        persistent
                                        full-width
                                        width="290px"
                                        :color="color.theme"
                                      >
                                        <template v-slot:activator="{ on }">
                                          <v-text-field
                                            readonly
                                            :color="color.theme"
                                            :label="
                                              $t(
                                                'myinboxPage.conditionOptional.todatedocument'
                                              )
                                            "
                                            v-model="todatedocument"
                                            prepend-icon="event"
                                            @click:prepend="
                                              modalTodatedocument = true
                                            "
                                            v-on="on"
                                            outlined
                                            dense
                                            clearable
                                            :persistent-hint="todate_hint"
                                            class="my-hint-style pa-1 pt-0 pb-0"
                                            :disabled="
                                              fromdatedocument === '' ||
                                                fromdatedocument === null
                                            "
                                          ></v-text-field>
                                          <!-- ปรับใหม่เป็นช่องวันที่แบบกรอก กดไอคอนเลือกวัน -->
                                          <!-- <v-text-field
                                        :color="color.theme"
                                        :label="$t('myinboxPage.conditionOptional.todatedocument')"
                                        v-model="todatedocument"
                                        prepend-icon="event"
                                        @click:prepend="modalTodatedocument = true, todatedocument = datenow_eng,on"
                                        :placeholder="$t('myinboxPage.yyyy_mm_dd')"
                                        outlined
                                        dense
                                        clearable
                                        :persistent-hint="todate_hint"
                                        class="my-hint-style pa-1 pt-0 pb-0"
                                      ></v-text-field> -->
                                        </template>
                                        <v-date-picker
                                          v-model="todatedocument"
                                          scrollable
                                          :locale="$t('default')"
                                          @input="
                                            $refs.dialogTodatedocument.save(
                                              todatedocument
                                            )
                                          "
                                          :min="fromdatedocument"
                                          :max="setdate(fromdatedocument)"
                                          :color="color.theme"
                                        >
                                          <v-spacer></v-spacer>
                                          <v-btn
                                            dark
                                            color="error"
                                            @click.stop="
                                              modalTodatedocument = false
                                            "
                                            >{{
                                              $t(
                                                "myinboxPage.conditionOptional.closetodatedocument"
                                              )
                                            }}</v-btn
                                          >
                                        </v-date-picker>
                                      </v-dialog>
                                    </v-flex>
                                  </v-layout>
                                  <v-layout row wrap justify-center>
                                    <v-flex xs2 lg3 mt-2 d-flex>
                                      <div>
                                        {{ $t("toolbar.searching.startdate") }}
                                      </div>
                                    </v-flex>
                                    <!-- ช่องวันที่ได้รับไฟล์ -->
                                    <v-flex xs10 lg3>
                                      <v-dialog
                                        ref="dialogFromdateupload"
                                        v-model="modalFromdateupload"
                                        :return-value.sync="fromdateupload"
                                        persistent
                                        full-width
                                        width="290px"
                                        :color="color.theme"
                                      >
                                        <template v-slot:activator="{ on }">
                                          <v-text-field
                                            clearable
                                            :color="color.theme"
                                            :label="
                                              $t(
                                                'myinboxPage.conditionOptional.fromdateupload'
                                              )
                                            "
                                            v-model="fromdateupload"
                                            @click:prepend="
                                              modalFromdateupload = true
                                            "
                                            append-icon="event"
                                            v-on="on"
                                            readonly
                                            outlined
                                            dense
                                            @click:clear="todateupload = ''"
                                            class="my-hint-style pa-1 pt-0 pb-0"
                                          ></v-text-field>
                                          <!-- ปรับใหม่เป็นช่องวันที่แบบกรอก กดไอคอนเลือกวัน -->
                                          <!-- <v-text-field
                                        clearable
                                        :color="color.theme"
                                        :label="$t('myinboxPage.conditionOptional.fromdateupload')"
                                        v-model="fromdateupload"
                                        prepend-icon="event" 
                                        @click:prepend="modalFromdateupload = true, fromdateupload = datenow_eng,on"
                                        :placeholder="$t('myinboxPage.yyyy_mm_dd')"
                                        outlined
                                        dense
                                        @click:clear="todateupload = ''"
                                        class="my-hint-style pa-1 pt-0 pb-0"
                                        @input="format_input(fromdateupload)"

                                      ></v-text-field> -->
                                        </template>
                                        <v-date-picker
                                          v-model="fromdateupload"
                                          scrollable
                                          :locale="$t('default')"
                                          @input="changeFromdateupload()"
                                          :color="color.theme"
                                          :min="setdate_(todateupload)"
                                          :max="todateupload"
                                        >
                                          <v-spacer></v-spacer>
                                          <v-btn
                                            dark
                                            color="error"
                                            @click.stop="
                                              modalFromdateupload = false
                                            "
                                            >{{
                                              $t(
                                                "myinboxPage.conditionOptional.closefromdateupload"
                                              )
                                            }}</v-btn
                                          >
                                        </v-date-picker>
                                      </v-dialog>
                                    </v-flex>
                                    <!-- <v-flex mt-2 d-flex justify-center>-</v-flex> -->
                                    <v-flex xs2 lg3 mt-2 d-flex>
                                      <div>
                                        {{ $t("toolbar.searching.enddate") }}
                                      </div>
                                    </v-flex>
                                    <!-- ช่องวันที่ได้รับไฟล์ -->
                                    <v-flex xs10 lg3>
                                      <v-dialog
                                        ref="dialogTodateupload"
                                        v-model="modalTodateupload"
                                        :return-value.sync="todateupload"
                                        persistent
                                        full-width
                                        width="290px"
                                        :color="color.theme"
                                      >
                                        <template v-slot:activator="{ on }">
                                          <v-text-field
                                            clearable
                                            readonly
                                            :color="color.theme"
                                            :label="
                                              $t(
                                                'myinboxPage.conditionOptional.todateupload'
                                              )
                                            "
                                            v-model="todateupload"
                                            append-icon="event"
                                            @click:prepend="
                                              modalTodateupload = true
                                            "
                                            v-on="on"
                                            outlined
                                            dense
                                            class="my-hint-style pa-1 pt-0 pb-0"
                                          ></v-text-field>
                                          <!-- ปรับใหม่เป็นช่องวันที่แบบกรอก กดไอคอนเลือกวัน -->
                                          <!-- <v-text-field
                                        clearable
                                        :color="color.theme"
                                        :label="$t('myinboxPage.conditionOptional.todateupload')"
                                        v-model="todateupload"
                                        prepend-icon="event"
                                        @click:prepend="modalTodateupload = true, todateupload = datenow_eng,on"
                                        :placeholder="$t('myinboxPage.yyyy_mm_dd')"
                                        outlined
                                        dense
                                        class="my-hint-style pa-1 pt-0 pb-0"
                                      ></v-text-field> -->
                                        </template>
                                        <v-date-picker
                                          v-model="todateupload"
                                          scrollable
                                          :locale="$t('default')"
                                          @input="
                                            $refs.dialogTodateupload.save(
                                              todateupload
                                            )
                                          "
                                          :min="fromdateupload"
                                          :max="setdate(fromdateupload)"
                                          :color="color.theme"
                                        >
                                          <v-spacer></v-spacer>
                                          <v-btn
                                            dark
                                            color="error"
                                            @click.stop="
                                              modalTodateupload = false
                                            "
                                            >{{
                                              $t(
                                                "myinboxPage.conditionOptional.closetodateupload"
                                              )
                                            }}</v-btn
                                          >
                                        </v-date-picker>
                                      </v-dialog>
                                    </v-flex>
                                  </v-layout>
                                  <v-layout row wrap justify-center>
                                    <v-flex xs6 lg3>
                                      <v-text-field
                                        clearable
                                        outlined
                                        dense
                                        @click:clear="clearDocumentId"
                                        class="pa-1 pt-0 pb-0"
                                        v-model="documentid"
                                        :label="
                                          $t(
                                            'myinboxPage.conditionOptional.filedocumentid'
                                          )
                                        "
                                        :color="color.theme"
                                      ></v-text-field>
                                    </v-flex>
                                    <v-flex xs6 lg3>
                                      <v-text-field
                                        clearable
                                        outlined
                                        dense
                                        :disabled="!!!documentid"
                                        class="pa-1 pt-0 pb-0"
                                        v-model="documentidto"
                                        :label="
                                          $t(
                                            'myinboxPage.conditionOptional.todateupload'
                                          )
                                        "
                                        :color="color.theme"
                                      ></v-text-field>
                                    </v-flex>
                                  </v-layout>

                                  <!-- ช่องกรอก SellerTaxID -->
                                  <v-layout row wrap justify-center>
                                    <v-flex xs12 lg6>
                                      <v-text-field
                                        outlined
                                        dense
                                        clearable
                                        class="pa-1 pt-0 pb-0"
                                        v-model="sendername"
                                        :label="
                                          $t(
                                            'myinboxPage.conditionOptional.sendername2'
                                          )
                                        "
                                        append-icon="mdi-email-send"
                                        :color="color.theme"
                                      ></v-text-field>
                                    </v-flex>
                                    <v-flex xs12 lg6>
                                      <v-text-field
                                        clearable
                                        outlined
                                        dense
                                        class="pa-1 pt-0 pb-0"
                                        v-model="sellertaxid"
                                        :label="
                                          $t(
                                            'myinboxPage.conditionOptional.sellertaxid2'
                                          )
                                        "
                                        :color="color.theme"
                                      ></v-text-field>
                                    </v-flex>
                                  </v-layout>

                                  <!-- ช่องกรอก BuyerTaxID -->
                                  <v-layout row wrap justify-center>
                                    <v-flex xs12 sm12 md6 lg6>
                                      <v-text-field
                                        outlined
                                        dense
                                        clearable
                                        class="pa-1 pt-0 pb-0"
                                        v-model="receivername"
                                        :label="
                                          $t(
                                            'myinboxPage.conditionOptional.receivername2'
                                          )
                                        "
                                        append-icon="mdi-email-receive"
                                        :color="color.theme"
                                      ></v-text-field>
                                    </v-flex>
                                    <v-flex xs12 lg6>
                                      <v-text-field
                                        clearable
                                        outlined
                                        dense
                                        class="pa-1 pt-0 pb-0"
                                        v-model="buyertaxid"
                                        :label="
                                          $t(
                                            'myinboxPage.conditionOptional.buyertaxid2'
                                          )
                                        "
                                        :color="color.theme"
                                      ></v-text-field>
                                    </v-flex>
                                  </v-layout>

                                  <v-layout row wrap justify-center>
                                    <v-flex xs12 sm12 md6 lg6>
                                      <!-- ช่องเลือก ประเภทเอกสาร -->
                                      <v-select
                                        clearable
                                        outlined
                                        dense
                                        class="pa-1 pt-0 pb-0"
                                        v-model="documenttype"
                                        :items="listDoctype"
                                        item-value="document_type_code"
                                        :item-text="
                                          $t('default') === 'th'
                                            ? 'document_type_th'
                                            : 'document_type_eng'
                                        "
                                        :label="
                                          $t(
                                            'myinboxPage.conditionOptional.doctype'
                                          )
                                        "
                                        :color="color.theme"
                                        :item-color="color.theme"
                                        multiple
                                      >
                                        <template v-slot:prepend-item>
                                          <v-list-item
                                            ripple
                                            @click="fn_selectall"
                                          >
                                            <v-list-item-action>
                                              <v-icon
                                                :color="
                                                  documenttype.length > 0
                                                    ? 'indigo darken-4'
                                                    : ''
                                                "
                                              >
                                                {{ icon }}
                                              </v-icon>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                              <v-list-item-title>
                                                Select All
                                              </v-list-item-title>
                                            </v-list-item-content>
                                          </v-list-item>
                                          <v-divider class="mt-2"></v-divider>
                                        </template>
                                        <template
                                          v-slot:selection="{ item, index }"
                                        >
                                          <span v-if="index < maxDisplay"
                                            >{{
                                              $t("default") === "th"
                                                ? item.document_type_th
                                                : item.document_type_eng
                                            }}
                                            ,
                                          </span>
                                          <span
                                            v-if="index === maxDisplay"
                                            class="grey--text caption"
                                            >(+{{
                                              documenttype.length - maxDisplay
                                            }}
                                            ประเภท)</span
                                          >
                                        </template>
                                      </v-select>
                                    </v-flex>
                                    <!-- สถานะขอคืนภาษี -->
                                    <v-flex xs12 sm12 md6 lg6>
                                      <v-select
                                        clearable
                                        outlined
                                        dense
                                        class="pa-1 pt-0 pb-0"
                                        v-model="statusetax"
                                        :items="liststatusetax"
                                        item-value="statusetax_value"
                                        item-text="statusetax_type"
                                        :label="
                                          $t(
                                            'myinboxPage.conditionOptional.taxabb'
                                          )
                                        "
                                        :color="color.theme"
                                        :item-color="color.theme"
                                      >
                                        <!-- <template v-slot:prepend-item>
                                          <v-list-item
                                            ripple
                                            @click="fn_selectall_statusetax"
                                          >
                                            <v-list-item-action>
                                              <v-icon
                                                :color="
                                                  statusetax.length > 0
                                                    ? 'indigo darken-4'
                                                    : ''
                                                "
                                              >
                                                {{ icon_ }}
                                              </v-icon>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                              <v-list-item-title>
                                                Select All
                                              </v-list-item-title>
                                            </v-list-item-content>
                                          </v-list-item>
                                          <v-divider class="mt-2"></v-divider>
                                        </template> -->
                                      </v-select>
                                    </v-flex>
                                    <!-- chanel -->
                                    <!-- <v-flex xs12 sm12 md6 lg6>
                                  <v-select
                                    clearable
                                    outlined
                                    dense
                                    class="pa-1 pt-0 pb-0"
                                    v-model="chanel"
                                    :items="listDoctype"
                                    item-value="document_type"
                                    item-text="document_type"
                                    :label="$t('myinboxPage.conditionOptional.chanel')"
                                    prepend-icon="reorder"
                                    :color="color.theme"
                                    :item-color="color.theme"
                                  ></v-select>
                                </v-flex> -->
                                  </v-layout>
                                </v-container>
                              </v-flex>
                            </v-layout>
                          </v-form>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </div>
                </v-flex>
                <v-flex xs12 lg12 class="text-center">
                  <br />
                  <v-btn
                    outlined
                    class="ma-1"
                    color="#2D3B4E"
                    :disabled="loaddataprogress"
                    :loading="loaddataprogress"
                    @click="Clear_data()"
                  >
                    <v-icon left>mdi-refresh</v-icon>
                    {{ $t("myinboxPage.clear_data") }}
                  </v-btn>
                  <v-btn
                    class="ma-1 white--text"
                    :color="color.theme"
                    :disabled="loaddataprogress"
                    :loading="loaddataprogress"
                    @click="
                      (checkpagination = false), (page = 1), searchdocument()
                    "
                  >
                    <v-icon left>search</v-icon>
                    &nbsp;
                    {{ $t("myinboxPage.search") }}
                  </v-btn>
                  <!-- ลบจาก Filter -->
                  <!-- <v-btn
                    v-if="status_permission_inbox === 'AD'"
                    class="ma-1 white--text"
                    color="#C51D1D"
                    :loading="loaddataprogress"
                    @click="delete_filter()"
                  >
                    <v-icon left>mdi-trash-can-outline</v-icon>
                    {{ $t("myinboxPage.delete_filter") }}
                  </v-btn> -->
                </v-flex>
                <v-flex xs12 lg12 class="ma-4">
                    <v-layout>
                  <!-- ปุ่มเมนู อันเก่า -->
                  <v-flex xs4 lg4>
                    <!-- <div v-if="statusmutipledownload === false" class="pt-2">
                      <v-btn
                        @mouseover="show_menu = true"
                        @mouseleave="show_menu = false"
                        :disabled="sortStatus==='pending'"
                        :color="color.theme"
                        rounded
                        class="ma-1 white--text"
                        @click="statusmutipledownload = true,show_menu = false"
                        fab
                        small
                      >
                        <v-icon>apps</v-icon>&nbsp;
                        {{ $t('myinboxPage.multipledownload')}} ไม่ใช้
                        <v-badge
                          style="z-index:10000;bottom:-45px;left:-30px;"
                          :value="show_menu"
                          content="Menu"
                          transition="slide-y-transition"
                        ></v-badge>
                      </v-btn>
                    </div>
                    <div v-else class="pt-2">
                      <v-btn 
                      @mouseover="show_download = true"
                      @mouseleave="show_download = false"
                      fab 
                      small 
                      color="primary" 
                      class="ma-1" 
                      @click="multipledownload()">
                        <v-icon>mdi-download-multiple</v-icon>
                        <v-badge
                          style="z-index:10000;bottom:-45px;left:-30px;"
                          :value="show_download"
                          content="Multiple Download"
                          transition="slide-y-transition"
                        ></v-badge>
                      </v-btn>
                      <v-btn
                        @mouseover="show_delete = true"
                        @mouseleave="show_delete = false"
                        fab
                        small
                        color="error"
                        class="ma-1"
                        @click="multipleDelete()"
                        v-if="status_permission_inbox === 'AD'"
                      >
                        <v-icon color="white">delete</v-icon>
                        <v-badge
                          style="z-index:10000;bottom:-45px;left:-60px;"
                          :value="show_delete"
                          content="Multiple Delete"
                          transition="slide-y-transition"
                        ></v-badge>
                      </v-btn>
                      <v-btn                      
                        @mouseover="show_print = true"
                        @mouseleave="show_print = false"
                        fab
                        small
                        color="#5875D3"
                        class="white--text ma-1 pa-0"
                        @click="fn_printPDF()"
                      >
                        <v-icon>print</v-icon>
                        <v-badge
                          style="z-index:10000;bottom:-45px;left:-40px;"
                          :value="show_print"
                          content="print PDF"
                          transition="slide-y-transition"
                        ></v-badge>
                      </v-btn>
                      <v-btn
                        @mouseover="show_clear = true"
                        @mouseleave="show_clear = false"
                        fab
                        small
                        :color="color.theme"
                        :dark="color.darkTheme"
                        class="ma-1"
                        @click="(statusmutipledownload = false), clearmultipledownload(), show_clear = false"
                      >
                        <v-icon>clear</v-icon>
                        <v-badge
                          style="z-index:10000;bottom:-45px;left:-30px;"
                          :value="show_clear"
                          content="Clear"
                          transition="slide-y-transition"
                        ></v-badge>
                      </v-btn>
                    </div>-->
                  </v-flex>
                  <v-flex xs8 lg8 class="text-right">
                    <!-- หน้าจอธรรมดา -->
                    <div v-if="resolutionScreen >= 400">
                      <v-chip
                        class="ma-2"
                        :color="color.theme"
                        :text-color="color.chipText"
                      >
                        <v-icon left>mdi-file</v-icon>
                        {{
                          $t("myinboxPage.search_file") +
                            " " +
                            totalfile +
                            " " +
                            $t("myinboxPage.items") ||
                            0 + " " + $t("myinboxPage.items")
                        }}
                      </v-chip>
                    </div>
                    <!-- หน้าจอมือถือ -->
                    <div v-else>
                      <v-chip
                        class="mt-5 px-1"
                        color="#5AB685"
                        style="height: 32px; border-radius: 32px;"
                        :text-color="color.chipText"
                      >
                        <v-icon center size="18" class="mr-2"
                          >mdi-file-document</v-icon
                        >
                        {{
                          $t("myinboxPage.search_file") +
                            " " +
                            totalfile +
                            " " +
                            $t("myinboxPage.items") ||
                            0 + " " + $t("myinboxPage.items")
                        }}
                      </v-chip>
                    </div>
                  </v-flex>
                    </v-layout>
                </v-flex>

                <v-flex xs12 lg12 class="ma-2 mt-2" v-if="loaddataprogress === true">
                  <div class="text-center">
                    <v-progress-circular
                      :size="50"
                      :color="color.theme"
                      indeterminate
                    ></v-progress-circular>
                  </div>
                </v-flex>
                <v-flex xs11 lg12 v-else class="ma-2 mt-2">
                  <div v-if="resolutionScreen < 500">
                    <v-list
                      two-line
                      class="mb-6 pb-6 elevation-1"
                      v-if="inboxdata.length !== 0"
                    >
                      <v-list-item
                        v-for="item in inboxdata"
                        :key="item.title"
                        style="cursor: pointer;"
                      >
                        <v-list-item-avatar v-show="statusmutipledownload">
                          <v-checkbox
                            v-model="filemultipledownload"
                            :value="item"
                            v-if="
                              item.status !== 'pending' &&
                                item.trash !== 'DELETE' &&
                                item.data_type !== null
                            "
                          />
                        </v-list-item-avatar>
                        <v-list-item-avatar>
                          <v-icon
                            large
                            :color="seticon_new(item.data_type)[1]"
                            style="font-weight: 100"
                            >{{ seticon_new(item.data_type)[0] }}</v-icon
                          >
                        </v-list-item-avatar>

                        <v-list-item-content @click="previewfile(item)">
                          <v-list-item-title
                            v-text="item.filename"
                          ></v-list-item-title>
                          <v-list-item-subtitle
                            v-text="item.document_no"
                          ></v-list-item-subtitle>
                          <div>
                            <v-chip
                              x-small
                              dark
                              :color="
                                item.status === 'pending'
                                  ? '#ffcc00'
                                  : item.status === 'reject'
                                  ? 'red'
                                  : item.status === 'unsend'
                                  ? '#ff8533'
                                  : item.status === 'request'
                                  ? 'primary'
                                  : item.status === 'request_delete_log'
                                  ? '#FF0000'
                                  : item.status ===
                                    'pending_receiver_stoage_full'
                                  ? '#ffcc00'
                                  : item.status === 'duplicate'
                                  ? 'primary'
                                  : 'success'
                              "
                              >{{
                                item.status === "request_delete_log"
                                  ? "ขอลบ"
                                  : item.status
                              }}</v-chip
                            >
                            <v-icon
                              v-if="item.trash === 'DELETE'"
                              color="red"
                              small
                              >cancel_presentation</v-icon
                            >
                            <v-icon
                              v-else-if="item.trash === 'T'"
                              color="amber"
                              small
                              >delete_sweep</v-icon
                            >
                          </div>
                        </v-list-item-content>
                        <v-list-item-action>
                          <!-- @click="downloadfile(item)" -->
                          <v-menu>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                class="elevation-0"
                                fab
                                small
                                v-on="on"
                                outlined
                                :disabled="item.status === 'pending'"
                              >
                                <v-icon>more_vert</v-icon>
                              </v-btn>
                            </template>
                            <v-list>
                              <v-list-item @click="previewfile(item)">
                                <v-list-item-icon>
                                  <v-icon>visibility</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-title>{{
                                    $t("currentRClick.preview")
                                  }}</v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                              <v-list-item
                                @click="(opendialog = true), (newdata = item)"
                                :disabled="disableDataStorageOverload"
                              >
                                <v-list-item-icon>
                                  <v-icon>mdi-information</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-title>{{
                                    $t("myinboxPage.Filedetails")
                                  }}</v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                              <!-- <v-list-item @click="opendialogtrue = true, newdata=item">
                              <v-list-item-icon>
                                <v-icon>folder</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title>{{$t('myinboxPage.Relateddocument')}}</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item> -->
                              <!-- @click="disableByPackage ? '' : downloadfile(item)" 
                            :ripple="!disableByPackage" 
                              :disabled="disableByPackage" -->
                              <v-tooltip
                                bottom
                                attach
                                content-class="mt-n14"
                                v-model="showToolTipDownloadFile"
                                :disabled="!disableDataStorageOverload"
                              >
                                <template v-slot:activator="{ on }">
                                  <v-list-item
                                    @click="
                                      disableDataStorageOverload
                                        ? ''
                                        : downloadfile(item)
                                    "
                                    v-if="
                                      (item.trash !== 'DELETE' &&
                                        status_permission_inbox === 'AD') ||
                                        status_permission_inbox === 'DL'
                                    "
                                    :ripple="!disableDataStorageOverload"
                                    v-on="on"
                                    :disabled="disableDataStorageOverload"
                                  >
                                    <v-list-item-icon>
                                      <v-icon>mdi-download-multiple</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                      <v-list-item-title>{{
                                        $t("dowloads")
                                      }}</v-list-item-title>
                                    </v-list-item-content>
                                    <!-- <v-expand-transition>
                                <v-overlay absolute color="grey lighten-1" :value="disableDataStorageOverload">
                                  <span>{{ $t("myinboxPage.storageisfull") }}</span>
                                </v-overlay>
                              </v-expand-transition>                             -->
                                  </v-list-item>
                                </template>
                                <span>{{
                                  $t("myinboxPage.storageisfull")
                                }}</span>
                              </v-tooltip>
                              <!-- ลบลงถังขยะ -->
                              <v-list-item
                                @click="fn_confirmdelete(item)"
                                v-if="status_permission_inbox === 'AD'"
                              >
                                <v-list-item-icon>
                                  <v-icon>delete</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-title>{{
                                    $t("checkdelete.delete")
                                  }}</v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                              <!-- check ขอลบ -->
                              <!-- <v-list-item v-if="item.status !== 'request_delete_log'" @click="opendialogdelete = true ,data=item ,statusdelete='N'">
                              <v-list-item-icon>
                                <v-icon>delete</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title>{{$t('checkdelete.deleteonlyme')}}</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item> -->
                              <!-- <v-list-item v-if="item.status !== 'request_delete_log'" @click="opendialogdelete = true ,data=item ,statusdelete='Y'">
                              <v-list-item-icon>
                                <v-icon>delete</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title>{{$t('checkdelete.deleteandrequest')}}</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item> -->
                              <!-- ตั้งค่าเวลาหมดอายุ -->
                              <!-- <v-list-item @click="fn_openset(), (newdata = item)">
                              <v-list-item-icon>
                                <v-icon>mdi-folder-clock</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                ตั้งค่าวันหมดอายุ
                              </v-list-item-content>
                            </v-list-item> -->
                              <!-- ยืนยัน request_delete_log -->
                              <v-list-item
                                v-if="item.status === 'request_delete_log'"
                              >
                                <v-list-item-icon>
                                  <v-icon>delete</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content class="pr-2">
                                  <v-btn
                                    color="green"
                                    dark
                                    dense
                                    @click="
                                      (opendialogrequestdelete = true),
                                        (data = item),
                                        (statusrequestdelete = 'Y')
                                    "
                                    >ยืนยัน</v-btn
                                  >
                                </v-list-item-content>
                                <v-list-item-content>
                                  <v-btn
                                    color="red"
                                    dark
                                    dense
                                    @click="deleterequest(item, 'N')"
                                    >ไม่ยืนยัน</v-btn
                                  >
                                </v-list-item-content>
                              </v-list-item>

                              <!-- active -->
                              <v-list-item
                                v-if="
                                  (item.status === 'pending' &&
                                    item.trash === 'T') ||
                                    (item.status === 'pending' &&
                                      item.trash === null)
                                "
                              >
                                <v-list-item-icon>
                                  <v-icon>insert_drive_file</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content class="pr-2">
                                  <v-btn
                                    color="success"
                                    dense
                                    @click="approvefile(item)"
                                    >Active</v-btn
                                  >
                                </v-list-item-content>
                                <v-list-item-content>
                                  <v-btn
                                    color="red"
                                    dark
                                    outlined
                                    dense
                                    @click="rejectfile(item)"
                                    >Reject</v-btn
                                  >
                                </v-list-item-content>
                              </v-list-item>
                              <!-- pending -->
                              <v-list-item v-if="item.status === 'reject'">
                                <v-list-item-icon>
                                  <v-icon>insert_drive_file</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                  <v-btn
                                    color="#ffcc00"
                                    dark
                                    dense
                                    @click="pendingfile(item)"
                                    >Pending</v-btn
                                  >
                                </v-list-item-content>
                              </v-list-item>
                              <v-list-item
                                v-if="
                                  item.status === 'request' &&
                                    item.trash !== 'DELETE' &&
                                    item.trash !== 'T'
                                "
                              >
                                <div class="pr-3 pl-2">
                                  <v-btn
                                    color="amber"
                                    dark
                                    @click="btnunsend(item)"
                                    >Unsend</v-btn
                                  >
                                </div>
                                <div>
                                  <v-btn
                                    class
                                    color="red"
                                    dark
                                    @click="btnnotapprove(item)"
                                    >No</v-btn
                                  >
                                </div>
                              </v-list-item>
                            </v-list>
                          </v-menu>
                        </v-list-item-action>
                      </v-list-item>
                      <!-- 
                    <v-divider inset></v-divider>-->
                    </v-list>
                    <v-list v-else class="elevation-1">
                      <v-list-item>
                        <!-- หน้าจอธรรมดา -->
                        <v-list-item-content v-if="resolutionScreen >= 500">
                          <v-list-item-title
                            class="text-center"
                            v-text="$t('tablefile.empty')"
                          ></v-list-item-title>
                        </v-list-item-content>
                        <!-- หน้าจอมือถือ -->
                        <v-list-item-content v-else>
                          <v-card-text class="mt-4 mb-4">
                            <v-img
                              :src="
                                require('../assets/img/icon_empty_data.png')
                              "
                              max-width="132"
                              max-height="150"
                              class="mx-auto"
                            />
                            <v-list-item-title
                              class="text-center mt-8"
                              v-text="$t('myoutboxPage.Nodata')"
                              :style="
                                'font-size: 16px; font-weight: 700; line-height: 24px; color:' +
                                  color.theme +
                                  ';'
                              "
                            ></v-list-item-title>
                          </v-card-text>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </div>
                  <div v-else>
                    <v-layout class="mb-2">
                      <div class="ml-2">
                        <div
                          v-if="statusmutipledownload === false"
                          class="pt-0"
                        >
                          <v-btn
                            @mouseover="show_menu = true"
                            @mouseleave="show_menu = false"
                            :disabled="sortStatus === 'pending'"
                            :color="color.theme"
                            rounded
                            class="ma-1 white--text"
                            @click="
                              (statusmutipledownload = true),
                                (show_menu = false)
                            "
                          >
                            <v-icon>mdi-menu</v-icon>&nbsp;
                            <p
                              class="ml-2"
                              style="font-size:15px;margin-bottom:2px;"
                            >
                              {{ $t("balloon.menu") }}
                            </p>
                            <!-- {{ $t('myinboxPage.multipledownload')}} -->
                            <v-badge
                              v-if="$t('default') === 'th'"
                              style="z-index:10000;bottom:-45px;left:-40px;"
                              :value="show_menu"
                              :content="$t('balloon.menu')"
                              transition="slide-y-transition"
                            ></v-badge>
                            <v-badge
                              v-else
                              style="z-index:10000;bottom:-45px;left:-55px;"
                              :value="show_menu"
                              :content="$t('balloon.menu')"
                              transition="slide-y-transition"
                            ></v-badge>
                          </v-btn>

                          <!-- <v-chip outlined  class="pl-9" style="margin-left:-30px" :color="color.theme" >  
                    {{$t('balloon.menu')}}
                    </v-chip> -->
                        </div>
                        <div v-else class="pt-2">
                          <!-- :color="disableDataStorageOverload ? '#D6D6D6' : 'primary'" 
                        @click="disableDataStorageOverload || disableByPackage ? '' : multipledownload()"
                        :ripple="!disableDataStorageOverload || !disableByPackage" -->
                          <v-btn
                            @mouseover="show_download = true"
                            @mouseleave="show_download = false"
                            fab
                            small
                            class="white--text ma-1"
                            :color="
                              disableDataStorageOverload ? '#D6D6D6' : 'primary'
                            "
                            @click="
                              disableDataStorageOverload
                                ? ''
                                : multipledownload()
                            "
                            :ripple="!disableDataStorageOverload"
                          >
                            <v-icon :disabled="disableDataStorageOverload"
                              >mdi-download-multiple</v-icon
                            >
                            <!-- <v-icon>mdi-download-multiple</v-icon> -->
                            <v-badge
                              v-if="$t('default') === 'th'"
                              style="z-index:10000;bottom:-45px;left:-30px;"
                              :value="show_download"
                              :content="
                                disableDataStorageOverload
                                  ? $t('myinboxPage.storageisfull')
                                  : $t('balloon.download')
                              "
                              transition="slide-y-transition"
                            ></v-badge>
                            <v-badge
                              v-else
                              style="z-index:10000;bottom:-45px;left:-30px;"
                              :value="show_download"
                              :content="
                                disableDataStorageOverload
                                  ? $t('myinboxPage.storageisfull')
                                  : $t('balloon.download')
                              "
                              transition="slide-y-transition"
                            ></v-badge>
                            <!-- <v-expand-transition>
                          <v-overlay absolute color="grey lighten-1" style="border-radius: 50%; width: 100%; height: 160%; margin-top: -7px;" :value="disableByPackage">
                            <v-icon>mdi-lock</v-icon>
                          </v-overlay>
                        </v-expand-transition> -->
                          </v-btn>
                          <v-btn
                            @mouseover="show_delete = true"
                            @mouseleave="show_delete = false"
                            fab
                            small
                            color="error"
                            class="ma-1"
                            @click="multipleDelete()"
                          >
                            <v-icon color="white">delete</v-icon>
                            <v-badge
                              v-if="$t('default') === 'th'"
                              style="z-index:10000;bottom:-45px;left:-22px;"
                              :value="show_delete"
                              :content="$t('balloon.delete')"
                              transition="slide-y-transition"
                            ></v-badge>
                            <v-badge
                              v-else
                              style="z-index:10000;bottom:-45px;left:-34px;"
                              :value="show_delete"
                              :content="$t('balloon.delete')"
                              transition="slide-y-transition"
                            ></v-badge>
                          </v-btn>
                          <v-btn
     
                            @mouseover="show_print = true"
                            @mouseleave="show_print = false"
                            fab
                            small
                            :color="
                              disableDataStorageOverload ? '#D6D6D6' : '#5875D3'
                            "
                            class="white--text ma-1 pa-0"
                            @click="
                              disableDataStorageOverload ? '' : fn_printPDF()
                            "
                            :ripple="!disableDataStorageOverload"
                          >
                            <v-icon :disabled="disableDataStorageOverload"
                              >print</v-icon
                            >
                            <v-badge
                              v-if="$t('default') === 'th'"
                              style="z-index:10000;bottom:-45px;left:-38px;"
                              :value="show_print"
                              :content="
                                disableDataStorageOverload
                                  ? $t('myinboxPage.storageisfull')
                                  : $t('balloon.print')
                              "
                              transition="slide-y-transition"
                            ></v-badge>
                            <v-badge
                              v-else
                              style="z-index:10000;bottom:-45px;left:-42px;"
                              :value="show_print"
                              :content="
                                disableDataStorageOverload
                                  ? $t('myinboxPage.storageisfull')
                                  : $t('balloon.print')
                              "
                              transition="slide-y-transition"
                            ></v-badge>
                          </v-btn>
                          <v-btn
                            @mouseover="show_clear = true"
                            @mouseleave="show_clear = false"
                            fab
                            small
                            :color="color.theme"
                            :dark="color.darkTheme"
                            class="ma-1"
                            @click="
                              (statusmutipledownload = false),
                                clearmultipledownload(),
                                (show_clear = false)
                            "
                          >
                            <v-icon>clear</v-icon>
                            <v-badge
                              v-if="$t('default') === 'th'"
                              style="z-index:10000;bottom:-45px;left:-20px;"
                              :value="show_clear"
                              :content="$t('balloon.clear')"
                              transition="slide-y-transition"
                            ></v-badge>
                            <v-badge
                              v-else
                              style="z-index:10000;bottom:-45px;left:-30px;"
                              :value="show_clear"
                              :content="$t('balloon.clear')"
                              transition="slide-y-transition"
                            ></v-badge>
                          </v-btn>
                        </div>
                      </div>

                      <v-flex d-flex justify-center>
                        <v-pagination
                        class="mr-14"
                          v-model="page"
                          :length="pageCount"
                          :color="color.theme"
                          :total-visible="10"
                          @input="clickpagination()"
                        ></v-pagination>
                      </v-flex>

                      <!-- <div
                        class="d-flex justify-end mb-4 px-1"
                        v-if="$route.query.type === '2'"
                      >
                        <v-btn
                          small
                          rounded
                          dark
                          :color="color.theme"
                          @click="opendialogmanageheader = true"
                          >{{ $t("myinboxPage.settingHeader.setting") }}</v-btn
                        >
                      </div> -->
                    </v-layout>
                    <!-- panigation อันเก่า  -->
                    <!-- <div class="text-xs-center ma-2">
                  <br />
                  <v-pagination
                    v-model="page"
                    :length="pageCount"
                    :color="color.theme"
                    :total-visible="10"
                    @input="clickpagination()"
                  ></v-pagination>
                </div> -->
                    <!-- {{inboxdata}} -->
                    <v-data-table
                      v-model="filemultipledownload"
                      :headers="headers"
                      :items="inboxdata"
                      item-key="file_id"
                      :search="search"
                      class="elevation-1"
                      hide-default-footer
                      :single-select="false"
                      :show-select="statusmutipledownload"
                      :page.sync="page"
                      :items-per-page="size"
                      @page-count="pageCount = $event"
                    >
                      <template v-slot:[`header.data_type`]="{ header }">
                        <span
                          :style="headerTable"
                          @click="
                            (sortby = 'status'),
                              (clickSortorderby = true),
                              (checkpagination = !checkpagination),
                              check()
                          "
                          >{{ header.text }}</span
                        >
                      </template>
                      <template v-slot:[`header.filename`]="{ header }">
                        <span :style="headerTable">{{ $t(header.text) }}</span>
                        <!-- คลิก Sort File จากหัวข้อ -->
                        <!-- <span
                        class="pointer"
                        @click="
                          (sortby = 'name'),
                            (clickSort = true),
                            (multiSort = !multiSort),
                            sortFile(inboxdata)
                        "
                        :style="headerTable"
                        >{{ $t(header.text) }}</span
                      > -->
                      </template>
                      <template v-slot:[`header.document_no`]="{ header }">
                        <span :style="headerTable">{{ $t(header.text) }}</span>
                        <!-- คลิก Sort File จากหัวข้อ -->
                        <!-- <span
                        class="pointer"
                        @click="
                          (sortby = 'docno'),
                            (clickSort = true),
                            (multiSort = !multiSort),
                            sortFile(inboxdata)
                        "
                        :style="headerTable"
                        >{{ $t(header.text) }}</span
                      > -->
                      </template>
                      <template v-slot:[`header.PO`]="{ header }">
                        <span :style="headerTable">{{ $t(header.text) }}</span>
                      </template>
                      <template v-slot:[`header.document_date`]="{ header }">
                        <span :style="headerTable">{{ $t(header.text) }}</span>
                        <!-- คลิก Sort File จากหัวข้อ -->
                        <!-- <span
                        class="pointer"
                        @click="
                          (sortby = 'docdate'),
                            (clickSort = true),
                            (multiSort = !multiSort),
                            sortFile(inboxdata)
                        "
                        :style="headerTable"
                        >{{ $t(header.text) }}</span
                      > -->
                      </template>
                      <template v-slot:[`header.document_type`]="{ header }">
                        <span :style="headerTable">{{ $t(header.text) }}</span>
                        <!-- คลิก Sort File จากหัวข้อ -->
                        <!-- <span
                        class="pointer"
                        @click="
                          (sortby = 'doctype'),
                            (clickSort = true),
                            (multiSort = !multiSort),
                            sortFile(inboxdata)
                        "
                        :style="headerTable"
                        >{{ $t(header.text) }}</span
                      > -->
                      </template>
                      <template v-slot:[`header.sender_name`]="{ header }">
                        <span :style="headerTable">{{ $t(header.text) }}</span>
                        <!-- คลิก Sort File จากหัวข้อ -->
                        <!-- <span
                        class="pointer"
                        @click="
                          (sortby = 'sendname'),
                            (clickSort = true),
                            (multiSort = !multiSort),
                            sortFile(inboxdata)
                        "
                        :style="headerTable"
                        >{{ $t(header.text) }}</span
                      > -->
                      </template>
                      <!-- <template v-slot:header.receiver_name="{ header }">
                        <span class="pointer" @click="sortby='receivername',clickSort=true,multiSort=!multiSort,sortFile(inboxdata)  "
                          :style="headerTable"
                        >{{ $t(header.text) }}</span>
                    </template> -->
                      <template v-slot:[`header.cre_dtm`]="{ header }">
                        <span :style="headerTable">{{ $t(header.text) }}</span>
                        <!-- คลิก Sort File จากหัวข้อ -->
                        <!-- <span
                        class="pointer"
                        @click="
                          (sortby = 'inboxdate'),
                            (clickSort = true),
                            (multiSort = !multiSort),
                            sortFile(inboxdata)
                        "
                        :style="headerTable"
                        >{{ $t(header.text) }}</span
                      > -->
                      </template>
                      <!-- สถานะขอใบกำกับภาษี -->
                      <template v-slot:[`header.tax_refund`]="{ header }">
                        <span :style="headerTable">{{ $t(header.text) }}</span>
                        <!-- <v-icon small>mdi-arrow-down-drop-circle-outline</v-icon> -->
                        <!-- <v-select
                          outlined
                          dense
                          v-model="multistatus_etax"
                          label="ตั้งค่าสถานะขอคืนภาษี"
                          :items="liststatusetax"
                          item-value="statusetax_value"
                          item-text="statusetax_type"
                          :disabled="sortStatus === 'pending'"
                          @change="
                            fn_changestatusetax_multi(
                              inboxdata,
                              multistatus_etax
                            )
                          "
                        ></v-select> -->
                      </template>

                      <template v-slot:[`header.file_id`]="{ header }">
                        <span :style="headerTable">{{ $t(header.text) }}</span>
                      </template>

                      <template v-slot:item="props">
                        <tr @dblclick="previewfile(props.item)">
                          <td
                            width="1%"
                            class="text-center"
                            v-show="statusmutipledownload"
                          >
                            <v-checkbox
                              v-model="filemultipledownload"
                              :value="props.item"
                              v-if="
                                props.item.trash !== 'DELETE' &&
                                  props.item.status !== 'pending' &&
                                  props.item.data_type !== null
                              "
                            />
                          </td>
                          <td width="5%" class="text-center">
                            <div>
                              <v-badge
                                color="red"
                                overlap
                                icon="clear"
                                v-if="props.item.trash === 'DELETE'"
                              >
                                <v-icon
                                  large
                                  :color="seticon_new(props.item.data_type)[1]"
                                  >{{
                                    seticon_new(props.item.data_type)[0]
                                  }}</v-icon
                                >
                              </v-badge>
                              <v-badge
                                color="amber"
                                overlap
                                icon="delete_sweep"
                                v-else-if="props.item.trash === 'T'"
                              >
                                <v-icon
                                  large
                                  :color="seticon_new(props.item.data_type)[1]"
                                  >{{
                                    seticon_new(props.item.data_type)[0]
                                  }}</v-icon
                                >
                              </v-badge>
                              <!-- <v-badge
                              color="primary"
                              overlap
                              icon="mdi-folder-clock"
                              v-else-if="props.item.expired_inbox !== '' "
                            >
                              <v-icon
                                large
                                :color="seticon(props.item.data_type)[1]"
                              >{{ seticon(props.item.data_type)[0]}}</v-icon>
                            </v-badge> -->
                              <v-icon
                                v-else
                                large
                                :color="seticon_new(props.item.data_type)[1]"
                                >{{
                                  seticon_new(props.item.data_type)[0]
                                }}</v-icon
                              >

                              <v-chip
                                x-small
                                dark
                                :color="
                                  props.item.status === 'pending'
                                    ? '#ffcc00'
                                    : props.item.status === 'reject'
                                    ? 'red'
                                    : props.item.status === 'unsend'
                                    ? '#ff8533'
                                    : props.item.status === 'request'
                                    ? 'primary'
                                    : props.item.status === 'request_delete_log'
                                    ? '#FF0000'
                                    : props.item.status ===
                                      'pending_receiver_stoage_full'
                                    ? '#ffcc00'
                                    : props.item.status === 'duplicate'
                                    ? 'primary'
                                    : 'success'
                                "
                                >{{
                                  props.item.status === "request_delete_log"
                                    ? "ขอลบ"
                                    : props.item.status
                                }}</v-chip
                              >
                            </div>
                          </td>
                          <td
                            width="5%"
                            :title="props.item.filename"
                            v-if="
                              dataAccountActive.type === 'Citizen' ||
                                feature.filename === 'Y'
                            "
                          >
                            {{ props.item.filename | subStr }}
                          </td>
                          <td
                            width="10%"
                            class="text-center"
                            v-if="
                              dataAccountActive.type === 'Citizen' ||
                                feature.document_no === 'Y'
                            "
                          >
                            {{ props.item.document_no | subStr }}
                          </td>
                          <td
                            width="10%"
                            class="text-center"
                            v-if="feature.PO === 'Y'"
                          >
                            {{
                              props.item.po_number === "" ||
                              props.item.po_number === null
                                ? "-"
                                : props.item.po_number
                            }}
                          </td>
                          <td
                            width="15%"
                            class="text-center"
                            v-if="
                              dataAccountActive.type === 'Citizen' ||
                                feature.document_date === 'Y'
                            "
                          >
                            {{ setformatdate(props.item.document_date) }}
                            <!-- <v-icon v-if="props.item.expired_inbox !== null " color="error">
                            mdi-folder-clock
                          </v-icon> -->
                          </td>
                          <td
                            width="10%"
                            class="text-center"
                            v-if="
                              dataAccountActive.type === 'Citizen' ||
                                feature.document_type === 'Y'
                            "
                          >
                            <v-chip
                              default
                              :color="$vuetify.theme.dark ? '#1E1E1E' : 'white'"
                              >{{ props.item.document_type }}</v-chip
                            >
                          </td>
                          <td
                            width="10%"
                            class="text-center"
                            v-if="
                              dataAccountActive.type === 'Citizen' ||
                                feature.seller_name === 'Y'
                            "
                          >
                            <v-chip
                              default
                              :color="$vuetify.theme.dark ? '#1E1E1E' : 'white'"
                              >{{ props.item.sender_name }}</v-chip
                            >
                          </td>
                          <!-- <td width="10%" class="text-center">{{props.item.receiver_name}}</td> -->
                          <td
                            width="15%"
                            class="text-center"
                            v-if="
                              dataAccountActive.type === 'Citizen' ||
                                feature.cre_dtm === 'Y'
                            "
                          >
                            {{ formatdatetime(props.item.cre_dtm) }}
                          </td>
                          <td
                            width="15%"
                            class="text-center"
                            v-if="
                              dataAccountActive.type === 'Citizen' ||
                                feature.status_onechat === 'Y'
                            "
                          >
                            <v-select
                              dense
                              outlined
                              class="text-center ma-2"
                              v-model="props.item.tax_refund"
                              :items="liststatusetax"
                              item-value="statusetax_value"
                              item-text="statusetax_type"
                              :disabled="
                                props.item.status === 'pending' ||
                                  props.item.data_type === null
                              "
                              @change="
                                fn_changestatusetax(
                                  props.item,
                                  props.item.tax_refund
                                )
                              "
                            ></v-select>
                          </td>
                          <td width="5%" class="text-center">
                            <v-menu top offset-y>
                              <template v-slot:activator="{ on }">
                                <v-btn
                                  class="elevation-0"
                                  fab
                                  small
                                  v-on="on"
                                  outlined
                                  :disabled="
                                    props.item.status === 'pending' ||
                                      props.item.data_type === null
                                  "
                                >
                                  <v-icon>more_vert</v-icon>
                                </v-btn>
                              </template>
                              <v-list>
                                <v-list-item @click="previewfile(props.item)">
                                  <v-list-item-icon>
                                    <v-icon>visibility</v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-content>
                                    <v-list-item-title>{{
                                      $t("currentRClick.preview")
                                    }}</v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                                <!-- รายละเอียดไฟล์ -->
                                <v-tooltip
                                  bottom
                                  attach
                                  content-class="mt-n14"
                                  v-model="showToolTipFileDetail"
                                  :disabled="!disableDataStorageOverload"
                                >
                                  <template v-slot:activator="{ on }">
                                    <v-list-item
                                      @click="
                                        disableDataStorageOverload
                                          ? ''
                                          : (opendialog = true),
                                          (newdata = props.item)
                                      "
                                      :ripple="!disableDataStorageOverload"
                                      v-on="on"
                                    >
                                      <v-list-item
                                        class="pa-0"
                                        :disabled="disableDataStorageOverload"
                                      >
                                        <v-list-item-icon>
                                          <v-icon>mdi-information</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                          <v-list-item-title>{{
                                            $t("myinboxPage.Filedetails")
                                          }}</v-list-item-title>
                                        </v-list-item-content>
                                      </v-list-item>
                                    </v-list-item>
                                  </template>
                                  <span>{{
                                    $t("myinboxPage.storageisfull")
                                  }}</span>
                                </v-tooltip>
                                <!-- เอกสารที่เกี่ยวข้อง -->
                                <!-- <v-list-item @click=" opendialogtrue = true, newdata=props.item">
                                <v-list-item-icon>
                                  <v-icon>folder</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-title>{{$t('myinboxPage.Relateddocument')}}</v-list-item-title>
                                </v-list-item-content>
                              </v-list-item> -->
                                <v-tooltip
                                  bottom
                                  attach
                                  content-class="mt-n14"
                                  v-model="showToolTipDownloadFile"
                                  :disabled="!disableDataStorageOverload"
                                >
                                  <template v-slot:activator="{ on }">
                                    <!-- @click="disableDataStorageOverload || disableByPackage ? '' : downloadfile(props.item)"
                                    :ripple="!disableDataStorageOverload || !disableByPackage" -->
                                    <v-list-item
                                      v-if="
                                        (props.item.trash !== 'DELETE' &&
                                          status_permission_inbox === 'AD') ||
                                          status_permission_inbox === 'DL'
                                      "
                                      @click="
                                        disableDataStorageOverload
                                          ? ''
                                          : downloadfile(props.item)
                                      "
                                      v-on="on"
                                      :ripple="!disableDataStorageOverload"
                                    >
                                      <!-- v-if="props.item.trash === 'T' || props.item.trash === alive" -->
                                      <!-- <v-list-item class="pa-0" :disabled="disableDataStorageOverload || disableByPackage"> -->
                                      <v-list-item
                                        class="pa-0"
                                        :disabled="disableDataStorageOverload"
                                      >
                                        <v-list-item-icon>
                                          <v-icon>mdi-download-multiple</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                          <v-list-item-title>{{
                                            $t("dowloads")
                                          }}</v-list-item-title>
                                        </v-list-item-content>
                                      </v-list-item>
                                      <!-- <v-expand-transition>
                                      <v-overlay absolute color="grey lighten-1" :value="disableByPackage">
                                        <v-icon>mdi-lock</v-icon>
                                      </v-overlay>
                                    </v-expand-transition> -->
                                    </v-list-item>
                                  </template>
                                  <span>{{
                                    $t("myinboxPage.storageisfull")
                                  }}</span>
                                </v-tooltip>
                                <!-- ลบลงถังขยะ -->
                                <v-list-item
                                  @click="fn_confirmdelete(props.item)"
                                  v-if="status_permission_inbox === 'AD'"
                                >
                                  <v-list-item-icon>
                                    <v-icon>delete</v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-content>
                                    <v-list-item-title>{{
                                      $t("checkdelete.delete")
                                    }}</v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                                <!-- ปุ่มการขอลบ ของตัวเอง-->
                                <!-- <v-list-item
                                v-if="props.item.status !== 'request_delete_log' "
                                @click="opendialogdelete = true ,data=props.item ,statusdelete='N'"
                              >
                                <v-list-item-icon>
                                  <v-icon>delete</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-title>{{$t('checkdelete.deleteonlyme')}}</v-list-item-title>
                                </v-list-item-content>
                              </v-list-item> -->
                                <!-- ปุ่มลบของตัวเองและrequest -->
                                <!-- <v-list-item
                                v-if="props.item.status !== 'request_delete_log' "
                                @click="opendialogdelete = true ,data=props.item ,statusdelete='Y'"
                              >
                                <v-list-item-icon>
                                  <v-icon>delete_forever</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-title>{{$t('checkdelete.deleteandrequest')}}</v-list-item-title>
                                </v-list-item-content>
                              </v-list-item> -->
                                <v-divider
                                  v-if="
                                    props.item.status === 'request_delete_log'
                                  "
                                ></v-divider>
                                <v-list-item
                                  v-if="
                                    props.item.status === 'request_delete_log'
                                  "
                                >
                                  <v-list-item-icon>
                                    <v-icon>
                                      delete
                                    </v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-content>
                                    <v-btn
                                      @click="
                                        (opendialogrequestdelete = true),
                                          (data = props.item),
                                          (statusrequestdelete = 'Y')
                                      "
                                      color="green"
                                      dark
                                    >
                                      <v-list-item-title>
                                        Accept
                                      </v-list-item-title>
                                    </v-btn>
                                  </v-list-item-content>
                                  <v-list-item-content class="pl-2">
                                    <v-btn
                                      @click="deleterequest(props.item, 'N')"
                                      color="red"
                                      dark
                                    >
                                      <v-list-item-title>
                                        Reject
                                      </v-list-item-title>
                                    </v-btn>
                                  </v-list-item-content>
                                </v-list-item>
                                <v-divider
                                  v-if="
                                    props.item.status === 'request' &&
                                      props.item.trash === 'alive'
                                  "
                                ></v-divider>
                                <v-list-item
                                  v-if="
                                    props.item.status === 'request' &&
                                      props.item.trash === 'alive'
                                  "
                                >
                                  <div class="pr-3 pl-2">
                                    <v-btn
                                      color="amber"
                                      dark
                                      @click="btnunsend(props.item)"
                                      >Unsend</v-btn
                                    >
                                  </div>
                                  <div>
                                    <v-btn
                                      class
                                      color="red"
                                      dark
                                      @click="btnnotapprove(props.item)"
                                      >No</v-btn
                                    >
                                  </div>
                                </v-list-item>
                                <!-- active -->
                                <v-list-item
                                  v-if="
                                    (props.item.status === 'pending' &&
                                      props.item.trash === 'T') ||
                                      (props.item.status === 'pending' &&
                                        props.item.trash === null)
                                  "
                                >
                                  <v-list-item-icon>
                                    <v-icon>insert_drive_file</v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-content class="pr-2">
                                    <v-btn
                                      color="success"
                                      dense
                                      @click="approvefile(props.item)"
                                      >Active</v-btn
                                    >
                                  </v-list-item-content>
                                  <v-list-item-content>
                                    <v-btn
                                      color="red"
                                      dark
                                      outlined
                                      dense
                                      @click="rejectfile(props.item)"
                                      >Reject</v-btn
                                    >
                                  </v-list-item-content>
                                </v-list-item>
                                <!-- pending -->
                                <v-list-item
                                  v-if="props.item.status === 'reject'"
                                >
                                  <v-list-item-icon>
                                    <v-icon>insert_drive_file</v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-content>
                                    <v-btn
                                      color="#ffcc00"
                                      dark
                                      dense
                                      @click="pendingfile(props.item)"
                                      >Pending</v-btn
                                    >
                                  </v-list-item-content>
                                </v-list-item>
                                <!-- ตั้งค่าเวลาหมดอายุ -->
                                <!-- <v-list-item @click="fn_openset(), (newdata = props.item)">
                                <v-list-item-icon>
                                   <v-icon>mdi-folder-clock</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                   ตั้งค่าวันหมดอายุ
                                </v-list-item-content>
                              </v-list-item> -->
                              </v-list>
                            </v-menu>
                          </td>
                        </tr>
                      </template>
                      <!-- <template  v-slot:item="{ items }">
                      <tbody>
                        <tr
                          v-for="(item, index) in items"
                          :key="index"
                          @dblclick="previewfile(item)"
                          style="cursor: pointer;"
                        >
                        <td class="text-center" v-show="statusmutipledownload">
                            <v-checkbox v-model="filemultipledownload" :value="item" />
                          </td>
                          <td class="text-center">
                            <v-icon
                              large
                              :color="seticon(item.data_type)[1]"
                            >{{ seticon(item.data_type)[0]}}</v-icon>
                          </td>
                          <td>{{ item.filename}}</td>
                          <td>{{ item.document_no}}</td>
                          <td class="text-center">{{ setformatdate(item.document_date)}}</td>
                          <td class="text-center">{{ item.document_type }}</td>
                          <td class="text-center">{{item.sender_name}}</td>
                          <td class="text-center">{{ formatdatetime(item.cre_dtm)}}</td>
                          <td class="text-center">
                            <v-btn class="elevation-0" fab small @click="downloadfile(item)">
                              <v-icon>mdi-download</v-icon>
                            </v-btn>
                          </td>
                        </tr>
                      </tbody>
                    </template>-->
                    </v-data-table>
                  </div>
                  <div class="text-xs-center">
                    <br />
                    <v-pagination
                      class="ml-11"
                      v-model="page"
                      :length="pageCount"
                      :color="color.theme"
                      :total-visible="10"
                      @input="clickpagination()"
                    ></v-pagination>
                  </div>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
        </v-card>
      </v-content>
    </v-app>
  </div>
</template>
<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import { mapState, mapGetters } from "vuex";
import { saveAs } from "file-saver";
import VueCookies from "vue-cookies";
import format from "date-fns/format";
import { set } from "date-fns";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
import gbfdecodearraybuffer from "@/globalFunctions/decodearraybuffer";
import onebox_toolbar from "../components/layout/layout-toolbar-new";
const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});
export default {
  name: "myinbox_citizen",
  components: { onebox_toolbar },
  data: function() {
    return {
      systemdirectory:0,
      type_inbox: "",
      list_documents: [],
      opendialogcheckdeletefilter: false,
      opendialogalertcheckdeletefilter: false,
      opendialogspecifyconditions: false,
      opendialogalersearchdonotfillout: false,
      show_info_doc: false,
      show_info: false,
      listdata: 0,
      opendialogalertresend: false,
      show_back: false,
      opendialogmanageheader: false,
      statusloading: "",
      count_loadingfile: 0,
      count_allfile: 0,
      closeOnContentClick: false,
      typedownloads: false,
      typeitem: false,
      showToolTipExportFile: false,
      showToolTipExportFilecsv: false,
      showToolTipExportFileExcel: false,
      showToolTipFileDetail: false,
      showToolTipDownloadFile: false,
      opensnackbarloadingprint: false,
      loaddataprogress_print: false,
      show_menu: false,
      show_download: false,
      show_delete: false,
      show_print: false,
      show_clear: false,
      show_download_all: false,
      show_download_all_csv: false,
      show_download_all_excel: false,
      sorting: {
        sort: [
          {
            id: "document_date",
            text: "sorting.document_date",
          },
          {
            id: "document_type",
            text: "sorting.document_type",
          },
          {
            id: "cre_dtm",
            text: "sorting.cre_dtm",
          },
          {
            id: "customer_name",
            text: "sorting.customer_name",
          },
          {
            id: "saler_name",
            text: "sorting.saler_name",
          },
          {
            id: "filename",
            text: "sorting.filename",
          },
          {
            id: "document_no",
            text: "sorting.doc_no",
          },
          {
            id: "status",
            text: "sorting.status",
          },
        ],
        direction: [
          {
            id: "ASC",
            text: "sorting.ascending",
          },
          {
            id: "DESC",
            text: "sorting.descending",
          },
        ],
      },
      sort_by: "filename",
      direction: "ASC",
      datenow: format(new Date(), "dd_MM_yyyy"),
      datenow_eng: format(new Date(), "yyyy-MM-dd"),
      timeout: -1,
      snackbar_download_error: false,
      snackbars: [],
      snackbar: {},
      multiLine: true,
      filename_error: "",
      errorMessage: "",
      snackbar: false,
      multistatus_etax: "",
      status_etax: "",
      statusetax: [],
      listheader: [],
      typedownload: "",
      opendialogselectheader: false,
      maxDisplayfileextension: 1,
      maxDisplay: 1,
      biz_id: "",
      checkallbiz: "",
      taxid_biz: "",
      checkdata: "",
      confirmdelete: false,
      listdata: "",
      opensdialogcheckdeleterightclick: false,
      btsdownload: false,
      opensdialogmultidelinboxoutbox: false,
      opendialogedit: false,
      opensnackbarmultidownload: false,
      percent: 0,
      data: "",
      statusdelete: "",
      statusrequestdelete: "",
      clickSortorderby: false,
      checkpagination: false,
      openreletion: false,
      openinbox: false,
      newdata: [],
      opendialog: false,
      opendialogtrue: false,
      opendialogdelete: false,
      opendialogrequestdelete: false,
      opendialogresend: false,
      items: [
        { title: "รายละเอียดไฟล์" },
        { title: "เอกสารที่เกี่ยวข้อง" },
        { title: "ดาวน์โหลด" },
      ],
      listcsv: [],
      namedowload: "",
      InboxFile: "InboxFile",
      ExportInboxFiles: "ExportInboxFiles",
      ExportInboxFilescsv: "ExportInboxFilescsv",
      ExportInboxFilesexcel: "ExportInboxFilesexcel",
      percentCompleted: 0,
      datetime: "",
      snackbardowload: false,
      snackbardowloadonefile: false,
      snackbardowloadonetotal: false,
      snackbardowloadonetotalcsv: false,
      snackbardowloadonetotalexcel: false,
      search: "",
      filemultipledownload: [],
      statusmutipledownload: false,
      liststatus: [
        "All",
        "Active",
        "Pending",
        "Duplicate",
        "request for removal",
        "resend from etax",
      ],
      // liststatus: ["All", "Active", "Pending", "Reject", "Request", "Unsend","Duplicate"],
      defaulticon: "",
      listtaxid: [
        {
          taxid: "0105517005330",
          namebiz: "บริษัท สรรพสินค้าเซ็นทรัล จำกัด",
        },
        {
          taxid: "0105511004676",
          namebiz: "บริษัท ห้างเซ็นทรัล ดีพาทเมนท์สโตร์ จำกัด",
        },
        {
          taxid: "0105511004650",
          namebiz: "บริษัท เตียง จิราธิวัฒน์ จำกัด",
        },
        {
          taxid: "0105558163762",
          namebiz: "บริษัท เซ็นทรัล เพย์เม้นท์ จำกัด",
        },
        {
          taxid: "0107562000386",
          namebiz: "บริษัท เซ็นทรัล รีเทล คอร์ปอเรชั่น จำกัด (มหาชน)",
        },
        {
          taxid: "0105535133093",
          namebiz: "บริษัท เซ็นทรัลแฟมิลี่มาร์ท จำกัด",
        },
        {
          taxid: "0105538032743",
          namebiz: "บริษัท บีทูเอส จำกัด",
        },
        {
          taxid: "0105544030277",
          namebiz: "บริษัท ซี อาร์ ซี เพาเวอร์ รีเทล จำกัด",
        },
        {
          taxid: "0105532021090",
          namebiz: "บริษัท เซ็นทรัล เรสตอรองส์ กรุ๊ป จำกัด",
        },
        {
          taxid: "0105547039585",
          namebiz: "บริษัท ซีอาร์จี อินเตอร์เนชั่นแนล ฟู้ด จำกัด",
        },
        {
          taxid: "0105537143215",
          namebiz: "บริษัท ออฟฟิศเมท (ไทย) จำกัด",
        },
        {
          taxid: "0105559006539",
          namebiz: "บริษัท เซ็นทรัล กรุ๊ป ออนไลน์ จำกัด",
        },
        {
          taxid: "0105558057016",
          namebiz: "บริษัท เซ็นเนอร์จี อินโนเวชั่น จำกัด",
        },
        {
          taxid: "0105539138812",
          namebiz: "บริษัท ซี อาร์ ซี สปอร์ต จำกัด",
        },
        {
          taxid: "0105560148929",
          namebiz: "บริษัท ไฮเท็คซ์ อินเตอร์แอคทีฟ จำกัด",
        },
        {
          taxid: "0105550099674",
          namebiz: "บริษัท เทราซอฟท์ โซลูชั่นส์ ดีเวลอปเม้นท์  จำกัด",
        },
        {
          taxid: "0105536044868",
          namebiz: "บริษัท อาร์ ไอ เอส จำกัด",
        },
        {
          taxid: "0905538002731",
          namebiz: "บริษัท ซีอาร์ หาดใหญ่ (ประเทศไทย) จำกัด",
        },
        {
          taxid: "0415538000849",
          namebiz: "บริษัท ซีอาร์ อุดรธานี (ประเทศไทย) จำกัด",
        },
        {
          taxid: "0505538005335",
          namebiz: "บริษัท ซีอาร์ เชียงใหม่ (ประเทศไทย) จำกัด",
        },
        {
          taxid: "0705538001125",
          namebiz: "บริษัท ซีอาร์ ราชบุรี (ประเทศไทย) จำกัด",
        },
        {
          taxid: "0105538069213",
          namebiz: "บริษัท ซีอาร์ (ประเทศไทย)  จำกัด",
        },
        {
          taxid: "0105554147665",
          namebiz: "บริษัท แมทเทอร์ ดี จำกัด",
        },
        {
          taxid: "0125558018410",
          namebiz: "บริษัท เซ็นทรัล และ มัทสึโมโตะ คิโยชิ จำกัด",
        },
        {
          taxid: "0105547041601",
          namebiz: "บริษัท เซ็นทรัล อินชัวรันส์ เซอร์วิสเซส จำกัด",
        },
        {
          taxid: "0105557044735",
          namebiz:
            "บริษัท หลักทรัพย์นายหน้าซื้อขายหน่วยลงทุน เซ็นทรัล เวลธ์ โซลูชั่น จำกัด",
        },
        {
          taxid: "0225539000097",
          namebiz: "บริษัท ซีอาร์ จันทบุรี (ประเทศไทย) จำกัด",
        },
        {
          taxid: "0107536000412",
          namebiz: "บริษัท โรบินสัน จำกัด (มหาชน)",
        },
        {
          taxid: "0105553072530",
          namebiz: "บริษัท เซ็นทรัลแอมบาสซีโฮเต็ล จำกัด",
        },
        {
          taxid: "0105561194797",
          namebiz: "บริษัท เดอะวันเซ็นทรัล จำกัด",
        },
        {
          taxid: "0105536071903",
          namebiz: "บริษัท ปริญญ์ อินเตอร์เทรด จำกัด",
        },
        {
          taxid: "0105521017584",
          namebiz: "บริษัท แบรนด์สมิท จำกัด",
        },
        {
          taxid: "0105531038428",
          namebiz: "บริษัท ห้องอาหาร ซี.ดี.เอส จำกัด",
        },
        {
          taxid: "0105500001158",
          namebiz: "บริษัท เซ็นทรัลเทรดดิ้ง จำกัด",
        },
        {
          taxid: "0105515001451",
          namebiz: "บริษัท เซ็นทรัลกาเมนท์แฟคทอรี่ จำกัด",
        },
        {
          taxid: "0105521007996",
          namebiz: "บริษัท เท็กซ์ทรัล เท็กซ์ไทลส์ จำกัด",
        },
        {
          taxid: "0105537127643",
          namebiz: "บริษัท เซ็นทรัลมาร์เก็ตติ้งกรุ๊ป จำกัด",
        },
        {
          taxid: "0105521019188",
          namebiz: "บริษัท ซีเทรคสากล จำกัด",
        },
        {
          taxid: "0125557004849",
          namebiz: "บริษัท เมพ คอร์ปอเรชั่น จำกัด",
        },
        {
          taxid: "0105518005643",
          namebiz: "บริษัท ปีเตอร์แอนด์จินนี่ จำกัด",
        },
        {
          taxid: "0105524005030",
          namebiz: "บริษัท เฟ็ดเดอริค เวอลด์เซลส์ จำกัด",
        },
        {
          taxid: "0105552007696",
          namebiz: "บริษัท ไทย วัตตส์ จำกัด",
        },
        {
          taxid: "0105527031261",
          namebiz: "บริษัท อัพ ฟรอนท์ จำกัด",
        },
        {
          taxid: "0105561088148",
          namebiz: "บริษัท ฟิวเจอร์พลัส จำกัด",
        },
        {
          taxid: "0105539113917",
          namebiz: "บริษัท ออฟฟิศเมท ออมนิแฟรนไชส์ จำกัด",
        },
        {
          taxid: "0105552051814",
          namebiz: "บริษัท ทรี พลัส บริการ จำกัด",
        },
        {
          taxid: "0105520002419",
          namebiz: "บริษัท เพลินฤดี จำกัด",
        },
        {
          taxid: "0105536110682",
          namebiz: "บริษัท เซ็นทรัลมาร์เก็ตติ้งกรุ๊ป อินเตอร์เทรด จำกัด",
        },
        {
          taxid: "0105532053242",
          namebiz: "บริษัท สวนนาเกลือ จำกัด",
        },
        {
          taxid: "0105532071615",
          namebiz: "บริษัท เซ็นทรัลธนบุรี จำกัด",
        },
        {
          taxid: "0105546106190",
          namebiz: "บริษัท ป่าตัน 1 จำกัด",
        },
        {
          taxid: "0105546133715",
          namebiz: "บริษัท ป่าตัน 2 จำกัด",
        },
        {
          taxid: "0105546133723",
          namebiz: "บริษัท ป่าตัน 3 จำกัด",
        },
        {
          taxid: "0105546133243",
          namebiz: "บริษัท ป่าตัน 4 จำกัด",
        },
        {
          taxid: "0105551040525",
          namebiz: "บริษัท เซ็นทรัล พีเพิล ดีเวลลอปเม้นท์ เซ็นเตอร์ จำกัด",
        },
        {
          taxid: "0105561095357",
          namebiz: "บริษัท เซ็นทรัลทำ วิสาหกิจเพื่อสังคม จำกัด",
        },
        {
          taxid: "0105536144455",
          namebiz: "บริษัท เดอะลิตเทิล คิทเช่น จำกัด",
        },
        {
          taxid: "0105531000641",
          namebiz: "บริษัท จิวเวลรี่ เรียลตี้ จำกัด",
        },
        {
          taxid: "0105554076601",
          namebiz: "บริษัท เฟลาเออร์ ชอพ จำกัด",
        },
        {
          taxid: "0105555142730",
          namebiz: "บริษัท ไดนิ่ง เซนเซชั่นส์ จำกัด",
        },
        {
          taxid: "0105534097280",
          namebiz: "บริษัท โอเวอร์ซีส์ แฟชั่นส์ จำกัด",
        },
        {
          taxid: "0107551000134",
          namebiz: "บริษัท ซีโอแอล จำกัด (มหาชน)",
        },
        {
          taxid: "0105555174178",
          namebiz: "บริษัท มูจิ รีเทล (ประเทศไทย) จำกัด",
        },
        {
          taxid: "0105539004352",
          namebiz: "บริษัท เพาเวอร์บาย จำกัด",
        },
        {
          taxid: "0105555021215",
          namebiz: "บริษัท ซีอาร์ซี ไทวัสดุ จำกัด",
        },
        {
          taxid: "0105535134278",
          namebiz: "บริษัท เซ็นทรัล ฟู้ด รีเทล จำกัด",
        },
        {
          taxid: "0105551120171",
          namebiz: "บริษัท เอเชีย มัลติเพิล จำกัด",
        },
        {
          taxid: "0105561097538",
          namebiz: "บริษัท ที่นี่ วงศ์สว่าง จำกัด",
        },
        {
          taxid: "0105561097619",
          namebiz: "บริษัท ที่นี่ ขอนแก่น จำกัด",
        },
        {
          taxid: "0105536137947",
          namebiz: "บริษัท บราเดอร์ โฮลดิ้ง จำกัด",
        },
        {
          taxid: "0105534115695",
          namebiz: "บริษัท แวนเทจ กราวด์ จำกัด",
        },
        {
          taxid: "0105536024352",
          namebiz: "บริษัท ซี.อาร์.จี.บริการ จำกัด",
        },
        {
          taxid: "0105542031772",
          namebiz: "บริษัท จักรวรรดิ โฮลดิ้ง จำกัด",
        },
        {
          taxid: "0105534118864",
          namebiz: "บริษัท ภูเก็ตฮิลล์ รีสอร์ท จำกัด",
        },
        {
          taxid: "0105537113138",
          namebiz: "บริษัท ซีดีเอส.โฮลดิ้ง จำกัด",
        },
        {
          taxid: "0105532035686",
          namebiz: "บริษัท ระยอง ซีวิว จำกัด",
        },
        {
          taxid: "0105532021693",
          namebiz: "บริษัท ชะอำ ซีวิว จำกัด",
        },
        {
          taxid: "0105531041551",
          namebiz: "บริษัท โปรเจ็คพัฒนา จำกัด",
        },
        {
          taxid: "0105536107045",
          namebiz: "บริษัท เซ็นทรัล โฮลดิ้ง จำกัด",
        },
        {
          taxid: "0105528029049",
          namebiz: "บริษัท อินฟินิตี้ แอสเซท จำกัด",
        },
        {
          taxid: "0105543042221",
          namebiz: "บริษัท สร้างสรรค์ธุรกิจ จำกัด",
        },
        {
          taxid: "0105521007571",
          namebiz: "บริษัท เซ็นทรัลอินเตอร์พัฒนา จำกัด",
        },
        {
          taxid: "0105561070095",
          namebiz: "บริษัท เซ็นทรัล เจดี มันนี่ จำกัด",
        },
        {
          taxid: "0105562174668",
          namebiz: "บริษัท กรีน ฟู้ด แฟคทอรี่ จำกัด",
        },
      ],
      listExtensionFile: [
        // {
        //   data_type: "pdf",
        // },
        // {
        //   data_type: "xml",
        // },
      ],
      listDoctype: [
        {
          document_type_code: "80",
          document_type_eng: "Debit Note (80)",
          document_type_th: "ใบเพิ่มหนี้ (80)",
        },
        {
          document_type_code: "81",
          document_type_eng: "Credit Note (81)",
          document_type_th: "ใบลดหนี้ (81)",
        },
        {
          document_type_code: "207",
          document_type_eng: "Delivery Note (207)",
          document_type_th: "ใบส่งของ (207)",
        },
        {
          document_type_code: "325",
          document_type_eng: "Performa Invoice (325)",
          document_type_th: "บัญชีสินค้าเดินพิธี (325)",
        },
        {
          document_type_code: "380",
          document_type_eng: "Invoice (380)",
          document_type_th: "ใบแจ้งหนี้ (380)",
        },
        {
          document_type_code: "381",
          document_type_eng: "Credit Note (381)",
          document_type_th: "ใบลดหนี้การค้า (381)",
        },
        {
          document_type_code: "383",
          document_type_eng: "Dabit Note (383)",
          document_type_th: "ใบเพิ่มหนี้การค้า (383)",
        },
        {
          document_type_code: "388",
          document_type_eng: "Tax Invoice (388)",
          document_type_th: "ใบกำกับภาษี (388)",
        },
        {
          document_type_code: "623",
          document_type_eng: "Forwarder's Invoice (623)",
          document_type_th: "ใบเสร็จค่า Freight (623)",
        },
        {
          document_type_code: "T01",
          document_type_eng: "Bill (T01)",
          document_type_th: "ใบรับ (ใบเสร็จรับเงิน) (T01)",
        },
        {
          document_type_code: "T02",
          document_type_eng: "Invoice/Tax Invoice (T02)",
          document_type_th: "ใบแจ้งหนี้/ใบกำกับภาษี (T02)",
        },
        {
          document_type_code: "T03",
          document_type_eng: "Bill/Tax Invoice (T03)",
          document_type_th: "ใบเสร็จรับเงิน/ใบกำกับภาษี (T03)",
        },
        {
          document_type_code: "T04",
          document_type_eng: "Delivery Note/Tax Invoice (T04)",
          document_type_th: "ใบส่งของ/ใบกำกับภาษี (T04)",
        },
        {
          document_type_code: "T05,T06",
          document_type_eng: "ABB (T05,T06)",
          document_type_th: "ใบกำกับภาษีอย่างย่อ (T05,T06)",
        },
        {
          document_type_code: "T07",
          document_type_eng: "Cancellation Note (T07)",
          document_type_th: "ใบยกเลิก (T07)",
        },
      ],
      currentfile: {},
      openpreviewfile: false,
      nodata: this.$t("myinboxPage.Nodata"),
      panel: [0],
      filename: "",
      fileextension: [],
      documentid: "",
      documentidto: "",
      documenttype: [],
      sendername: "",
      systemname: "",
      sellertaxid: "",
      sellerbranchid: "",
      ponumberinput: "",
      receivername: "",
      buyertaxid: "",
      buyerbranchid: "",
      fromdateupload: "",
      modalFromdateupload: false,
      todateupload: "",
      modalTodateupload: false,
      fromdatedocument: "",
      modalFromdatedocument: false,
      todatedocument: "",
      modalTodatedocument: false,
      loaddataprogress: false,
      inboxdata: [],
      headers: [
        {
          text: "#",
          align: "center",
          sortable: false,
          value: "data_type",
          width: "5%",
        },
        {
          text: "tablefile.filenameonly",
          lign: "left",
          value: "filename",
          sortable: false,
          width: "10%",
        },
        {
          text: "tablefile.docno",
          align: "center",
          value: "document_no",
          sortable: false,
          width: "5%",
        },
        {
          text: "tablefile.docdate",
          align: "center",
          value: "document_date",
          sortable: false,
          width: "5%",
        },
        {
          text: "tablefile.doctype",
          value: "document_type",
          align: "center",
          sortable: false,
          width: "10%",
        },
        {
          text: "tablefile.sendername",
          value: "sender_name",
          align: "center",
          sortable: false,
          width: "10%",
        },
        // {
        //   text: "tablefile.receivername",
        //   value: "receiver_name",
        //   align: "left",
        //   sortable: false,
        //   width: "10%"
        // },
        {
          text: "tablefile.inboxdate",
          value: "cre_dtm",
          align: "center",
          sortable: false,
          width: "10%",
        },
        {
          text: "สถานะขอใบกำกับภาษี",
          value: "tax_refund",
          align: "center",
          sortable: false,
          width: "15%",
        },
        {
          text: "tablefile.option",
          value: "file_id",
          align: "left",
          sortable: false,
          width: "5%",
        },
      ],
      fromdate: "",
      fromdate_hint: "",
      todate_hint: "",
      size: 10,
      statusde: "All",
      sortStatus: "",
      page: 1,
      totalfile: 0,
      total_delete_file: 0,
      sortby: "",
      clickSort: false,
      folderSort: [],
      fileSort: [],
      sortdata: [],
      buff: [],
      multiSort: false,
      liststatusetax: [
        {
          statusetax_type: "ยืนคำขอใบกำกับภาษีแล้ว",
          statusetax_value: "Y",
        },
        {
          statusetax_type: "ยังไม่ได้ยื่นคำขอใบกำกับภาษี",
          statusetax_value: "N",
        },
        // {
        //   statusetax_type: "ขอคืนภาษีแล้ว",
        //   statusetax_value: "Y",
        // },
        // {
        //   statusetax_type: "ยังไม่ขอคืนภาษี",
        //   statusetax_value: "N",
        // },
      ],
      feature: {},
      // package: "",
    };
  },
  computed: {
    ...mapState([
      "username",
      "authorize",
      "account_active",
      "service",
      "color",
    ]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
      "dataStorageUsage",
      "dataStorageMax",
    ]),
    parentfolder() {
      return this.dataAccountActive["directory_id"];
    },
    // Labeldata() {
    //     // this.defaulticon = "face";
    //     // this.getSourceSystem();
    //     return this.$t("toolbar.myinbox.Personalname");

    // },
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    pageCount: {
      get() {
        let l = this.systemdirectory.length;
        let s = this.size;
        return Math.ceil(l / s);
      },
      set(newName) {
        return newName;
      },
    },
    paginatedData() {
      const start = (this.page - 1) * this.size;
      const end = start + this.size;
      return this.systemdirectory.slice(start, start + this.size);
    },
    showdatafilter() {
      // console.log(search);
      return this.systemdirectory.filter((item) => {
        return (
          item.system.toLowerCase().indexOf(this.search.toLowerCase()) > -1
        );
      });
    },
    headerTable() {
      return (
        "color:" + this.color.theme + ";" + "font-weight: 600; font-size:15px;"
      );
    },
    headerPage() {
      return (
        "color:" + this.color.theme + ";" + "font-size: 18px; font-weight: 600;"
      );
    },
    colorVapp() {
      return "background:" + this.color.BG + ";";
    },
    colorProgress() {
      return "color:" + this.color.theme + ";";
    },
    icon() {
      if (this.Alldoctype) return "mdi-close-box";
      if (this.Somedoctype) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    icons() {
      if (this.Allfileextension) return "mdi-close-box";
      if (this.Somefileextension) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    icon_() {
      if (this.Allstatusetax) return "mdi-close-box";
      if (this.Somestatusetax) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    Alldoctype() {
      return this.documenttype.length === this.listDoctype.length;
    },
    Somedoctype() {
      return this.documenttype.length > 0 && !this.Alldoctype;
    },
    Allfileextension() {
      console.log("this.fileextension.length", this.fileextension.length);
      console.log(
        "this.listExtensionFile.length",
        this.listExtensionFile.length
      );
      return this.fileextension.length === this.listExtensionFile.length;
    },
    Somefileextension() {
      return this.fileextension.length > 0 && !this.Allfileextension;
    },
    Allstatusetax() {
      console.log(this.statusetax.length === this.liststatusetax.length);
      return this.statusetax.length === this.liststatusetax.length;
    },
    Somestatusetax() {
      return this.statusetax.length > 0 && !this.Allstatusetax;
    },
    disableByPackage() {
      return this.dataCheckPackage.type === "Business"
        ? this.dataCheckPackage.package
          ? false
          : true
        : this.dataCheckPackage.package
        ? false
        : true;
      // console.log('this.package',this.package);
      // return !(this.package === 'Y')
    },
    disableDataStorageOverload() {
      return (
        this.convertstorageToByte(this.storage_usage_biz) >
        this.convertstorageToByte(this.dataStorageMax)
      );
    },
  },
  methods: {
    convertstorageToByte(_storage) {
      console.log(_storage);
      let splitStorage = _storage.split(" ");
      let databyte = 0;

      if (splitStorage[1] === "KB") {
        databyte =
          typeof splitStorage[0] === "number"
            ? splitStorage[0] * 1024
            : parseFloat(splitStorage[0]) * 1024;
      } else if (splitStorage[1] === "MB") {
        databyte =
          typeof splitStorage[0] === "number"
            ? splitStorage[0] * 1048576
            : parseFloat(splitStorage[0]) * 1048576;
      } else if (splitStorage[1] === "GB") {
        databyte =
          typeof splitStorage[0] === "number"
            ? splitStorage[0] * 1073741824
            : parseFloat(splitStorage[0]) * 1073741824;
      } else if (splitStorage[1] === "TB") {
        databyte =
          typeof splitStorage[0] === "number"
            ? splitStorage[0] * 1099511627776
            : parseFloat(splitStorage[0]) * 1099511627776;
      } else {
        databyte =
          typeof splitStorage[0] === "number"
            ? splitStorage[0]
            : parseFloat(splitStorage[0]);
      }
      return Math.ceil(databyte);
    },
    fn_selectall_fileextension() {
      let listex = [];
      this.$nextTick(() => {
        if (this.Allfileextension) {
          this.fileextension = [];
          console.log("fileextension", this.fileextension);
        } else {
          console.log(this.fileextension);
          console.log(this.listExtensionFile);
          for (let i = 0; i < this.listExtensionFile.slice().length; i++) {
            listex.push(this.listExtensionFile[i].data_type);
          }
          this.fileextension = listex;
          // // this.fileextension = this.listExtensionFile.slice()
          // console.log("fileextension else",this.fileextension);
        }
      });
    },
    fn_selectall() {
      let listdocument = [];
      this.$nextTick(() => {
        console.log(this.Alldoctype);
        if (this.Alldoctype) {
          this.documenttype = [];
          console.log("documenttype", this.documenttype);
        } else {
          for (let i = 0; i < this.listDoctype.slice().length; i++) {
            listdocument.push(this.listDoctype[i].document_type);
          }
          this.documenttype = listdocument;
          console.log("documenttype else", this.documenttype);
        }
      });
    },
    fn_selectall_statusetax() {
      this.$nextTick(() => {
        if (this.Allstatusetax) {
          this.statusetax = [];
          console.log("statusetax", this.statusetax);
        } else {
          this.statusetax = this.liststatusetax.slice();
          console.log("this.statusetax", this.statusetax);
        }
      });
    },
    clearDocumentId() {
      this.documentidto = "";
    },
    filteredItems(search) {
      this.search = search;
      // console.log(search);
      // console.log("in",this.inboxdata);
    },
    setdate_(date_) {
      if (date_) {
        console.log("date_", date_);
        var date = new Date(date_);
        // var y = date.getFullYear()
        // var m = date.getMonth() + 1
        // var d = date.getDay() + 90
        date.setDate(date.getDate() - 90);
        console.log(date);
        return new Date(date).toISOString().substr(0, 10);
      }
      return date_;
    },
    setdate(date_) {
      if (date_) {
        console.log("date_", date_);
        var date = new Date(date_);
        // var y = date.getFullYear()
        // var m = date.getMonth() + 1
        // var d = date.getDay() + 90
        date.setDate(date.getDate() + 90);
        console.log(date);
        return new Date(date).toISOString().substr(0, 10);
      }
      return date_;
      // if(date_ === ''){
      //   var date = new Date(),
      //     y = date.getFullYear(),
      //     m = date.getMonth(),
      //     d = date.getDate() + 90;
      //   console.log("d", d);
      //   var Day = new Date(y, m, d).toISOString().substr(0, 10);
      //   console.log(Day);
      //   return Day;
      // }
    },
    fn_getinboxcitizen(){
        
    }
  },
  mounted() {},
};
</script>
<style>
.v-snack__content {
  padding-top: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
}
#thiscontainer_inboxsourcesystem_citizen {
  /* height: calc(101vh - 180px);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  -moz-overflow-scrolling: touch;
  -ms-overflow-scrolling: touch; */
  height: calc(101vh - 180px);
  /* height: calc(var(--vh, 1vh) * 100); */
  overflow-x: hidden;
  /* max-width: 100%; */
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  -moz-overflow-scrolling: touch;
  -ms-overflow-scrolling: touch;
}
.pointer {
  cursor: pointer;
}

#btn {
  text-transform: none;
}
.select {
  max-height: 20px;
}
</style>
